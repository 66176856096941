// Core
import React from 'react';
import { shallowEqual } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
// Other
import { useTypedSelector } from './redux';
import { AuthRoutes } from './routes/Auth';
import { PersonalRoutes } from './routes/Personal';

const App: React.FC = () => {
  const { isLoggedIn } = useTypedSelector(({ auth }) => auth, shallowEqual);

  return (
    <>
      <ToastContainer />
      <BrowserRouter>{isLoggedIn ? <PersonalRoutes /> : <AuthRoutes />}</BrowserRouter>
    </>
  );
};

export default App;
