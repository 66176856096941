// Core
import { toast } from 'react-toastify';
// Types
import { ThunkDispatch } from 'redux-thunk';
import { CombinedState } from 'redux';
import {
  AnalyticsData, VisitorsData, LocationsData, AnalyticsVisitorsData, PrefilledVisitors,
} from '../redux/types/total';
import { LocationsOption, Mode } from '../types';
import { AuthActionTypes, LoginApiData, LoginData } from '../redux/types/auth';
// Actions
// eslint-disable-next-line import/no-cycle
import { logout } from '../redux/actions/auth';
// Other
import { ROOT } from './config';
import { getAuthHeaders } from '../utils/helpers';
import { AuthState } from '../redux/reducers/auth';
import { TotalState } from '../redux/reducers/total';

type SearchBody = {
  startDate: string;
  endDate: string;
  mode: Mode;
  locations: LocationsOption[];
};

export type FetchDataType<T> = (
  dispatch: ThunkDispatch<CombinedState<{
    auth: AuthState,
    total: TotalState
  }>, unknown, AuthActionTypes>
) => Promise<T>;

export type FetchAnalyticsDataType<T> = (
  body: SearchBody,
  dispatch: ThunkDispatch<CombinedState<{
    auth: AuthState,
    total: TotalState
  }>, unknown, AuthActionTypes>
) => Promise<T>;

export type FetchPrefilledVisitorsDataType<T> = (
  year: number,
  week: number,
  dispatch: ThunkDispatch<CombinedState<{
    auth: AuthState,
    total: TotalState
  }>, unknown, AuthActionTypes>
) => Promise<T>;

export type FetchVisitorsDataType<T> = (
  body: VisitorsData,
  dispatch: ThunkDispatch<CombinedState<{
    auth: AuthState,
    total: TotalState
  }>, unknown, AuthActionTypes>
) => Promise<T>;

export type FetchLoginDataType<T> = (body: LoginData) => Promise<T>;

type APIFetchDataType = {
  auth: {
    fetchLogin: FetchLoginDataType<LoginApiData>;
  };
  analytics: {
    fetchWebAnalytics: FetchAnalyticsDataType<AnalyticsData>;
    fetchTouchAnalytics: FetchAnalyticsDataType<AnalyticsData>;
    fetchManualVisitors: FetchAnalyticsDataType<AnalyticsVisitorsData>;
  };
  fetchLocations: FetchDataType<LocationsData>;
  visitors: {
    fetchPrefilledVisitors: FetchPrefilledVisitorsDataType<PrefilledVisitors>;
    fetchVisitors: FetchVisitorsDataType<unknown>;
  };
};

export const api: APIFetchDataType = {
  auth: {
    fetchLogin: (body: LoginData): Promise<LoginApiData> => fetch(`${ROOT}/login`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    }).then((response) => {
      if (response.status === 401) {
        toast('Invalid credentials', { type: 'error' });
        return Promise.reject(response);
      }
      return response.json();
    }),
  },
  analytics: {
    fetchManualVisitors: (
      body: SearchBody,
      dispatch: ThunkDispatch<CombinedState<{
        auth: AuthState,
        total: TotalState
      }>, unknown, AuthActionTypes>,
    ): Promise<AnalyticsVisitorsData> => fetch(`${ROOT}/manual/locations/analytics`, {
      method: 'POST',
      headers: getAuthHeaders(true),
      body: JSON.stringify(body),
    }).then((response) => {
      if (response.status === 401 || response.status === 403) {
        dispatch(logout());
        toast('Login expired, please login again', { type: 'error' });
        return Promise.reject(response);
      }
      return response.json();
    }),
    fetchWebAnalytics: (
      body: SearchBody,
      dispatch: ThunkDispatch<CombinedState<{
        auth: AuthState,
        total: TotalState
      }>, unknown, AuthActionTypes>,
    ): Promise<AnalyticsData> => fetch(`${ROOT}/analytics/google`, {
      method: 'POST',
      headers: getAuthHeaders(true),
      body: JSON.stringify(body),
    }).then((response) => {
      if (response.status === 401 || response.status === 403) {
        dispatch(logout());
        toast('Login expired, please login again', { type: 'error' });
        return Promise.reject(response);
      }
      return response.json();
    }),
    fetchTouchAnalytics: (
      body: SearchBody,
      dispatch: ThunkDispatch<CombinedState<{
        auth: AuthState,
        total: TotalState
      }>, unknown, AuthActionTypes>,
    ): Promise<AnalyticsData> => fetch(`${ROOT}/analytics/firebase`, {
      method: 'POST',
      headers: getAuthHeaders(true),
      body: JSON.stringify(body),
    }).then((response) => {
      if (response.status === 401 || response.status === 403) {
        dispatch(logout());
        toast('Login expired, please login again', { type: 'error' });
        return Promise.reject(response);
      }
      return response.json();
    }),
  },
  fetchLocations: (
    dispatch: ThunkDispatch<CombinedState<{
      auth: AuthState,
      total: TotalState
    }>, unknown, AuthActionTypes>,
  ): Promise<LocationsData> => fetch(`${ROOT}/manual/locations`, {
    method: 'GET',
    headers: getAuthHeaders(),
  }).then((response) => {
    if (response.status === 401 || response.status === 403) {
      dispatch(logout());
      toast('Login expired, please login again', { type: 'error' });
      return Promise.reject(response);
    }
    return response.json();
  }),
  visitors: {
    fetchPrefilledVisitors: (
      year: number,
      week: number,
      dispatch: ThunkDispatch<CombinedState<{
        auth: AuthState,
        total: TotalState
      }>, unknown, AuthActionTypes>,
    ): Promise<PrefilledVisitors> => fetch(`${ROOT}/manual/locations/visitors/${year}/${week}`, {
      method: 'GET',
      headers: getAuthHeaders(),
    }).then((response) => {
      if (response.status === 401 || response.status === 403) {
        dispatch(logout());
        toast('Login expired, please login again', { type: 'error' });
        return Promise.reject(response);
      }
      return response.json();
    }),
    fetchVisitors: (
      body: VisitorsData,
      dispatch: ThunkDispatch<CombinedState<{
        auth: AuthState,
        total: TotalState
      }>, unknown, AuthActionTypes>,
    ): Promise<unknown> => fetch(`${ROOT}/manual/locations/visitors`, {
      method: 'POST',
      headers: getAuthHeaders(true),
      body: JSON.stringify(body),
    }).then((response) => {
      if (response.status === 401 || response.status === 403) {
        dispatch(logout());
        toast('Login expired, please login again', { type: 'error' });
        return Promise.reject(response);
      }
      return response.json();
    }),
  },
};
