// Core
import React, { Dispatch, SetStateAction, useMemo } from 'react';
import { shallowEqual } from 'react-redux';
import { Typeahead } from 'react-bootstrap-typeahead';
// Components
import { Label } from '../common/Label';
// Other
import { useTypedSelector } from '../../redux';
import { LocationsOption } from '../../types';
// Styles
import styles from './searchbar.module.sass';

interface ILocationsProps {
  selections: LocationsOption[];
  setSelections: Dispatch<SetStateAction<LocationsOption[]>>
}

export const LocationsMemoized: React.FC<ILocationsProps> = (props: ILocationsProps) => {
  const { selections, setSelections } = props;
  const { locations } = useTypedSelector(({ total }) => total, shallowEqual);

  const options: LocationsOption[] = useMemo(() => {
    const optionsLocations: LocationsOption[] = [];
    locations.forEach((l) => {
      optionsLocations.push({ name: l.name, id: l.id });
    });
    optionsLocations.unshift({ name: 'All locations' });
    return optionsLocations;
  }, [locations]);

  const onTypeaheadChanged = (selected: LocationsOption[]) => {
    const selectedLength: number = selected.length;
    if (selectedLength > 0 && selected[selectedLength - 1].name === 'All locations') {
      setSelections([{ name: 'All locations' }]);
    } else if (selectedLength > 1 && selected[0].name === 'All locations') {
      setSelections(selected.slice(1, selected.length));
    } else {
      setSelections(selected);
    }
  };

  return (
    <div className={`border p-4 mb-5 ${styles.bar__typehead}`}>
      <Label theme="blue" text="Locations / Units" side="left" />
      <Typeahead
        id="typeahead-multiple"
        multiple
        onChange={onTypeaheadChanged}
        options={options}
        placeholder="Choose locations"
        selected={selections}
        labelKey={(option) => option.name}
      />
    </div>
  );
};

export const Locations = React.memo(LocationsMemoized);
