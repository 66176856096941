import moment, { Moment } from 'moment';

export const COLORS = {
  blueText: 'rgba(136, 145, 159, 1)',
  greenText: 'rgba(144, 165, 156, 1)',
  greyText: 'grey',
  black: '#000000',
  transparent: 'rgba(0, 0, 0, 0)',
};

export const FULLSCREEN_MODAL_BREAKPOINT = 'lg-down';

export const MONTHS = [
  'JAN',
  'FEB',
  'MAR',
  'APR',
  'MAY',
  'JUN',
  'JUL',
  'AUG',
  'SEP',
  'OCT',
  'NOV',
  'DEC',
];

export const DAY_FORMAT = 'YYYY-MM-DD';

export const TODAY: string = moment().format(DAY_FORMAT);

export const YESTERDAY: Moment = moment().subtract(1, 'd');

export const WEEK_AGO: Moment = moment().subtract(7, 'd');
