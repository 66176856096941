// Core
import React from 'react';
import { shallowEqual } from 'react-redux';
// Components
import { LineChart } from '../common/LineChart';
// Hooks:
import { useDatesChartData } from '../../hooks/useDatesChartData';
// Other
import { useTypedSelector } from '../../redux';

export const ModulesPerSessionTouch: React.FC = () => {
  const {
    data: { modulesPerSessionTouch },
    mode,
    startDate,
    endDate,
  } = useTypedSelector(({ total }) => total, shallowEqual);
  const {
    labelsNames, chartData,
  } = useDatesChartData(mode, modulesPerSessionTouch, startDate, endDate);

  return (
    <>
      {chartData.length > 0 && (
      <LineChart
        theme="blue"
        title="Touchscreen Modules per Session"
        subtitle="Amount open modules per session. One session can be converted to one user."
        label="Modules"
        labelsNames={labelsNames}
        data={chartData}
      />
      )}
    </>
  );
};
