// Core
import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
// Components
import App from './App';
// Other
import { store } from './redux/store';
// Styles
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-dates/lib/css/_datepicker.css';
import 'react-toastify/dist/ReactToastify.css';
import './styles/index.sass';

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
);
