// Core
import React from 'react';
import { shallowEqual } from 'react-redux';
// Components
import { BarChart } from '../common/BarChart';
// Hooks:
import { useDatesChartData } from '../../hooks/useDatesChartData';
// Other
import { useTypedSelector } from '../../redux';

export const WebSessionTime: React.FC = () => {
  const {
    mode,
    data: { totalWebSessionTime },
    startDate,
    endDate,
  } = useTypedSelector(({ total }) => total, shallowEqual);
  const {
    labelsNames, chartData,
  } = useDatesChartData(mode, totalWebSessionTime, startDate, endDate);

  return (
    <>
      {chartData.length > 0 && (
        <BarChart
          theme="green"
          title="Website Session time"
          subtitle="Time spent on one session. One session is one unique user."
          labelsNames={labelsNames}
          data={chartData}
        />
      )}
    </>
  );
};
