// Core
import React, { useMemo } from 'react';
import { Col } from 'react-bootstrap';
import { ChartData, ChartOptions } from 'chart.js';
import { Bar } from 'react-chartjs-2';
// Components
import { Label } from '../Label';
// Other
import { getChartColor, getChartDarkColor } from '../../../utils/helpers';
import { IChartProps } from '../../../types';
import { COLORS } from '../../../utils/constants';
import { GenericData } from '../../../redux/types/total';

interface IHChartProps extends IChartProps {
  label: string;
  dataForChart: GenericData[] | null;
  children?: never;
}

const HorizontalChartMemoized: React.FC<IHChartProps> = (props:IHChartProps) => {
  const {
    title, subtitle, theme, label, dataForChart,
  } = props;
  const darkChartColor: string = useMemo(() => getChartDarkColor(theme), [theme]);
  const backgroundColor: string[] = useMemo(() => [getChartColor(theme)], [theme]);
  const options: ChartOptions = {
    plugins: { legend: { display: false }, tooltip: { enabled: false } },
    indexAxis: 'y',
    scales: {
      y: {
        grid: { display: false },
        ticks: {
          color: COLORS.black,
          font: {
            size: 12,
          },
        },
      },
      x: {
        beginAtZero: true,
        ticks: {
          color: theme === 'blue' ? COLORS.blueText : COLORS.greenText,
          font: {
            size: 12,
          },
        },
      },
    },
  };

  const data: ChartData | ((canvas: HTMLCanvasElement) => ChartData) = useMemo(() => {
    const labels: string[] = [];
    const chartData: number[] = [];
    if (dataForChart && dataForChart.length > 0) {
      dataForChart.forEach((s) => {
        labels.push(s.label);
        chartData.push(Number(s.value));
      });
    }

    return {
      labels,
      datasets: [
        {
          data: chartData,
          fill: true,
          backgroundColor,
          hoverBackgroundColor: darkChartColor,
          borderColor: [darkChartColor],
          borderWidth: 1,
        },
      ],
    };
  }, [dataForChart]);

  return (
    <>
      {dataForChart && dataForChart.length > 0 && (
        <Col lg={6} className="mb-3 mb-lg-0 page-break-inside">
          <div className="border p-2 page-break-inside">
            <h6 className="mb-0">{title}</h6>
            <p className="mb-4 subtitle">{subtitle}</p>
            <div className="border px-2 py-4 relative page-break-inside">
              <Label theme={theme} text={label} side="left" />
              <Bar className="page-break-inside" data={data} options={options} />
            </div>
          </div>
        </Col>
      )}
    </>
  );
};

HorizontalChartMemoized.defaultProps = {
  children: undefined,
};

export const HorizontalChart = React.memo(HorizontalChartMemoized);
