// Core
import React from 'react';
import { Row, Col, Spinner } from 'react-bootstrap';
import { shallowEqual } from 'react-redux';
// Components
import { HorizontalChart } from '../common/HorizontalBarChart';
import { HorizontalCharts } from '../common/HorizontalBarCharts';
import { WebSessions } from './WebSessions';
import { TouchSessions } from './TouchSessions';
import { TouchSessionTime } from './TouchSessionTime';
import { WebSessionTime } from './WebSessionTime';
import { VisitorsAnalytics } from './VisitorsAnalytics';
// Other
import { useTypedSelector } from '../../redux';

export const TotalTab: React.FC = () => {
  const {
    locations,
    data: {
      usageTouchPerModule,
      usagePerModule,
      usagePerLocation, usagePerLocationWeb,
      totalTouchSessions,
      totalWebSessions,
      visitorsAnalytics,
      totalTouchSessionTime,
      totalWebSessionTime,
    },
  } = useTypedSelector(({ total }) => total, shallowEqual);

  const totalUsageJSX = ((totalTouchSessions && totalTouchSessions.length > 0)
      || (totalWebSessions && totalWebSessions.length > 0)
      || (visitorsAnalytics && visitorsAnalytics.length > 0)) && (
      <>
        <h3 className="mb-3">Total usage / Sessions</h3>
        <Row className="mb-lg-3">
          <TouchSessions />
          <WebSessions />
        </Row>
        <Row className="mb-5">
          <VisitorsAnalytics />
        </Row>
      </>
  );
  const totalUsageTimeJSX = ((totalTouchSessionTime && totalTouchSessionTime.length > 0)
      || (totalWebSessionTime && totalWebSessionTime.length > 0)) && (
      <>
        <h3 className="mb-3">Total usage / Session Time</h3>
        <Row className="mb-5">
          <TouchSessionTime />
          <WebSessionTime />
        </Row>
      </>
  );
  const locationsUsageJSX = locations.length > 0 && (usagePerLocation || usagePerLocationWeb) && (
    <>
      <h3 className="mb-3">Total usage / Usage per location</h3>
      <Row className="mb-5">
        <HorizontalCharts />
      </Row>
    </>
  );
  const totalUsagePerModuleJSX = ((usageTouchPerModule && usageTouchPerModule.length > 0)
      || (usagePerModule && usagePerModule.length > 0)) && (
      <>
        <h3 className="mb-3">Total usage / Usage per module</h3>
        <Row className="mb-3">
          <HorizontalChart theme="blue" title="Touchscreen Usage per module" subtitle="Amount total usage of modules." label="Sessions" dataForChart={usageTouchPerModule} />
          <HorizontalChart theme="green" title="Website Usage per module" subtitle="Amount total usage of modules." label="Sessions" dataForChart={usagePerModule} />
        </Row>
      </>
  );

  if ((!totalTouchSessions || (totalTouchSessions && totalTouchSessions.length === 0))
      && (!totalWebSessions || (totalWebSessions && totalWebSessions.length === 0))
      && (!visitorsAnalytics || (visitorsAnalytics && visitorsAnalytics.length === 0))) {
    return (
      <div className="mt-5 loader-wrapper">
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  return (
    <Row>
      <Col>
        <div className="border px-3 py-2 mb-5">
          {totalUsageJSX}
          {totalUsageTimeJSX}
          {locationsUsageJSX}
          {totalUsagePerModuleJSX}
        </div>
      </Col>
    </Row>
  );
};
