// Core
import {
  createStore, applyMiddleware, Store,
} from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
// Instruments
import { rootReducer } from './rootReducer';

export const store: Store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));
