// Types
/* eslint import/no-cycle: [2, { maxDepth: 1 }] */
import {
  LOGIN,
  LOGOUT,
  AUTH_IS_FETCHING,
  AuthActionTypes,
} from '../types/auth';

export type AuthState = {
  isLoggedIn: boolean;
  loading: boolean;
};

const initialState: AuthState = {
  isLoggedIn: localStorage && localStorage.getItem('atgsIsLogged') === 'true',
  loading: false,
};

export function authReducer(state = initialState, action: AuthActionTypes): AuthState {
  switch (action.type) {
    case LOGIN:
      if (localStorage) localStorage.setItem('atgsIsLogged', 'true');
      if (localStorage) localStorage.setItem('atgsToken', action.payload.token);
      return {
        ...state,
        isLoggedIn: true,
      };
    case LOGOUT:
      if (localStorage) localStorage.removeItem('atgsIsLogged');
      if (localStorage) localStorage.removeItem('atgsToken');
      return {
        ...state,
        isLoggedIn: false,
      };
    case AUTH_IS_FETCHING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      // eslint-disable-next-line no-case-declarations, @typescript-eslint/no-unused-vars
      const x: never = action;
  }
  return state;
}
