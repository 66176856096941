// Core
import React from 'react';
import { shallowEqual } from 'react-redux';
// Components
import { MixedCharts } from '../common/MixedCharts';
// Hooks:
import { useMixedChartsData } from '../../hooks/useMixedChartsData';
// Other
import { useTypedSelector } from '../../redux';

export const Module1Guide2Touch: React.FC = () => {
  const {
    data: { module1guide2timeTouch, module1guide2Touch },
    mode,
  } = useTypedSelector(({ total }) => total, shallowEqual);
  const { labelsNames, data1, data2 } = useMixedChartsData(
    mode,
    module1guide2Touch,
    module1guide2timeTouch,
  );

  return (
    <>
      {labelsNames.length > 0 && (
      <MixedCharts
        theme="blue"
        title="Touchscreen Module 1 Drop-out / Plats Guide"
        subtitle="Amount of sessions per view. And session time per view."
        xLabels={labelsNames}
        data1={data1}
        data2={data2}
      />
      )}
    </>
  );
};
