// Core
import { ThunkAction } from 'redux-thunk';
// Other
/* eslint import/no-cycle: [2, { maxDepth: 1 }] */
import { AppState } from '../index';
import { ChosenLocations, DateRange, Mode } from '../../types';

export type Location = {
  id: number,
  'created_at': string | null;
  'updated_at': string | null;
  'deleted_at': string | null;
  name: string;
  serial: string;
};

export type LocationsData = Location[];

export type GenericData = {
  label: string;
  value: string;
};

export type AnalyticsVisitorsData = GenericData[] | null;

export type AnalyticsData = {
  // Total tab:
  total_web_sessions: GenericData[] | null;
  sessions_time: GenericData[] | null;
  usage_per_location: null | GenericData[];
  usage_per_module: GenericData[] | null;
  // Module tab:
  module_1_guide_1: GenericData[] | null;
  module_1_guide_1_time: GenericData[] | null;
  module_1_guide_2: GenericData[] | null;
  module_1_guide_2_time: GenericData[] | null;
  module_1_guide_3: GenericData[] | null;
  module_1_guide_3_time: GenericData[] | null;
  module_2_guide_1: GenericData[] | null;
  module_2_guide_1_time: GenericData[] | null;
  module_2_guide_2: GenericData[] | null;
  module_2_guide_2_time: GenericData[] | null;
  module_2_guide_3: GenericData[] | null;
  module_2_guide_3_time: GenericData[] | null;
  module_3_per_category: GenericData[] | null;
  module_3_per_article: GenericData[] | null;
  time_per_module: GenericData[] | null;
  time_per_guide: GenericData[] | null;
  website_navigation: GenericData[] | null;
  // Sessions tab:
  modules_per_session: GenericData[] | null;
  guides_per_session: GenericData[] | null;
  views_per_session: GenericData[] | null;
};

export type AnalyticsTouchData = {
  // Total tab:
  total_web_sessions: GenericData[] | null;
  sessions_time: GenericData[] | null;
  usage_per_location: null | GenericData[];
  usage_per_module: GenericData[] | null;
  // Module tab:
  module_1_guide_1: GenericData[] | null;
  module_1_guide_1_time: GenericData[] | null;
  module_1_guide_2: GenericData[] | null;
  module_1_guide_2_time: GenericData[] | null;
  module_1_guide_3: GenericData[] | null;
  module_1_guide_3_time: GenericData[] | null;
  module_2_guide_1: GenericData[] | null;
  module_2_guide_1_time: GenericData[] | null;
  module_2_guide_2: GenericData[] | null;
  module_2_guide_2_time: GenericData[] | null;
  module_2_guide_3: GenericData[] | null;
  module_2_guide_3_time: GenericData[] | null;
  module_3_per_category: GenericData[] | null;
  module_3_per_article: GenericData[] | null;
  time_per_module: GenericData[] | null;
  time_per_guide: GenericData[] | null;
  website_navigation: GenericData[] | null;
  // Sessions tab:
  modules_per_session: GenericData[] | null;
  guides_per_session: GenericData[] | null;
  views_per_session: GenericData[] | null;
};

export type Total = {
  // Total tab:
  totalTouchSessions: GenericData[] | null;
  totalWebSessions: GenericData[] | null;
  totalTouchSessionTime: GenericData[] | null;
  totalWebSessionTime: GenericData[] | null;
  visitorsAnalytics: GenericData[] | null;
  usagePerLocation: null | GenericData[];
  usagePerLocationWeb: null | GenericData[];
  usageTouchPerModule: GenericData[] | null;
  usagePerModule: GenericData[] | null;
  // Module tab:
  module1guide1Touch: GenericData[] | null;
  module1guide1: GenericData[] | null;
  module1guide1timeTouch: GenericData[] | null;
  module1guide1time: GenericData[] | null;
  module1guide2Touch: GenericData[] | null;
  module1guide2: GenericData[] | null;
  module1guide2timeTouch: GenericData[] | null;
  module1guide2time: GenericData[] | null;
  module1guide3Touch: GenericData[] | null;
  module1guide3: GenericData[] | null;
  module1guide3timeTouch: GenericData[] | null;
  module1guide3time: GenericData[] | null;
  module2guide1Touch: GenericData[] | null;
  module2guide1: GenericData[] | null;
  module2guide1timeTouch: GenericData[] | null;
  module2guide1time: GenericData[] | null;
  module2guide2Touch: GenericData[] | null;
  module2guide2: GenericData[] | null;
  module2guide2timeTouch: GenericData[] | null;
  module2guide2time: GenericData[] | null;
  module2guide3Touch: GenericData[] | null;
  module2guide3: GenericData[] | null;
  module2guide3timeTouch: GenericData[] | null;
  module2guide3time: GenericData[] | null;
  module3percategory: GenericData[] | null;
  module3percategoryTouch: GenericData[] | null;
  module3perarticle: GenericData[] | null;
  module3perarticleTouch: GenericData[] | null;
  timePerModuleTouch: GenericData[] | null;
  timePerModule: GenericData[] | null;
  timePerGuideTouch: GenericData[] | null;
  timePerGuide: GenericData[] | null;
  touchNavigation: GenericData[] | null;
  websiteNavigation: GenericData[] | null;
  // Sessions tab:
  modulesPerSessionTouch: GenericData[] | null;
  modulesPerSession: GenericData[] | null;
  guidesPerSessionTouch: GenericData[] | null;
  guidesPerSession: GenericData[] | null;
  viewsPerSessionTouch: GenericData[] | null;
  viewsPerSession: GenericData[] | null;
};

export type DayVisitors = {
  date: string;
  visitors: number;
};

export type PrefilledVisitorsRow = {
  Visitors: DayVisitors[];
  location: Location;
};

export type PrefilledVisitors = PrefilledVisitorsRow[];

export type ErrorAnalyticsApi = string;

export const LOCATIONS_FILL = 'LOCATIONS_FILL';
export type LocationsFillAction = {
  type: typeof LOCATIONS_FILL;
  payload: LocationsData;
};

export const DATE_RANGE_UPDATE = 'DATE_RANGE_UPDATE';
export type DateRangeUpdateAction = {
  type: typeof DATE_RANGE_UPDATE;
  payload: DateRange;
};

export const MODE_UPDATE = 'MODE_UPDATE';
export type ModeUpdateAction = {
  type: typeof MODE_UPDATE;
  payload: Mode;
};

export const LOCATIONS_CHOOSE = 'LOCATIONS_CHOOSE';
export type LocationsChooseAction = {
  type: typeof LOCATIONS_CHOOSE;
  payload: ChosenLocations;
};

export interface VisitorsData {
  [field: string]: string;
}

export const TOTAL_IS_FETCHING = 'TOTAL_IS_FETCHING';
type TotalIsFetchingAction = {
  type: typeof TOTAL_IS_FETCHING;
  payload: boolean;
};

export const ANALYTICS_FILL = 'ANALYTICS_FILL';
export type AnalyticsFillAction = {
  type: typeof ANALYTICS_FILL;
  payload: AnalyticsData;
};

export const ANALYTICS_TOUCH_FILL = 'ANALYTICS_TOUCH_FILL';
export type AnalyticsTouchFillAction = {
  type: typeof ANALYTICS_TOUCH_FILL;
  payload: AnalyticsTouchData;
};

export const ANALYTICS_VISITORS_FILL = 'ANALYTICS_VISITORS_FILL';
export type AnalyticsVisitorsFillAction = {
  type: typeof ANALYTICS_VISITORS_FILL;
  payload: AnalyticsVisitorsData;
};

// Visitors modal:
export const VISITORS_IS_FETCHING = 'VISITORS_IS_FETCHING';
type VisitorsIsFetchingAction = {
  type: typeof VISITORS_IS_FETCHING;
  payload: boolean;
};

export const VISITORS_FILL = 'VISITORS_FILL';
export type VisitorsFillAction = {
  type: typeof VISITORS_FILL;
  payload: PrefilledVisitors;
};

export type TotalActionTypes =
    | TotalIsFetchingAction
    | VisitorsIsFetchingAction
    | AnalyticsFillAction
    | AnalyticsTouchFillAction
    | AnalyticsVisitorsFillAction
    | VisitorsFillAction
    | LocationsFillAction
    | ModeUpdateAction
    | LocationsChooseAction
    | DateRangeUpdateAction;

export type TotalThunkAction = ThunkAction<Promise<void>, AppState, unknown, TotalActionTypes>;
