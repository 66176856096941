// Core
import React from 'react';
import {
  Row, Col, Button, Form, Container, Stack,
} from 'react-bootstrap';
import { shallowEqual, useDispatch } from 'react-redux';
import { Formik, FormikHelpers } from 'formik';
import * as yup from 'yup';
// Actions
import { postLogin } from '../../redux/actions/auth';
// Other
import { LoginData } from '../../redux/types/auth';
import { hasStringHTMLTags } from '../../utils/helpers';
import { useTypedSelector } from '../../redux';
// Styles
import styles from './login.module.sass';

const loginSchema = yup.object().shape({
  username: yup.string().required('Username is a required field')
    .test(
      'username',
      'Field shouldn\'t contain " <" or ">" characters',
      (val?: string) => hasStringHTMLTags(val),
    ),
  password: yup.string().required('Password is a required field')
    .max(32, 'Field should contain less or equal to 32 characters')
    .test(
      'password',
      'Field shouldn\'t contain " <" or ">" characters',
      (val?: string) => hasStringHTMLTags(val),
    ),
});

export const Login: React.FC = () => {
  const { loading } = useTypedSelector(({ auth }) => auth, shallowEqual);
  const dispatch = useDispatch();

  const handleLoginSubmit = (values: LoginData, actions: FormikHelpers<LoginData>) => {
    dispatch(postLogin(values, () => {
      actions.resetForm();
    }));
  };

  return (
    <Row className="full-height">
      <Col className="full-height">
        <Container className="full-height">
          <Stack className={`col-md-5 mx-auto ${styles.wrapper}`}>
            <Formik
              validationSchema={loginSchema}
              onSubmit={handleLoginSubmit}
              initialValues={{ username: '', password: '' }}
            >
              {({
                handleSubmit,
                handleChange,
                values,
                isValid,
                errors,
              }) => (
                <Form noValidate onSubmit={handleSubmit} className={styles.form}>
                  <Form.Group controlId="validationUsername" className={styles.group}>
                    <Form.Label>Username</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Username"
                      name="username"
                      value={values.username}
                      onChange={handleChange}
                      isInvalid={!!errors.username}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.username}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId="validationPassword" className={styles.group}>
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Password"
                      value={values.password}
                      onChange={handleChange}
                      name="password"
                      isInvalid={!!errors.password}
                    />
                    <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                  </Form.Group>
                  <Button disabled={!isValid || loading} variant="primary" type="submit" className={styles.button}>Login</Button>
                </Form>
              )}
            </Formik>
          </Stack>
        </Container>
      </Col>
    </Row>
  );
};
