// Types
import {
  LOGIN,
  LOGOUT,
  AUTH_IS_FETCHING,
  AuthActionTypes,
  AuthThunkAction,
  LoginData,
} from '../types/auth';
// Other
// eslint-disable-next-line import/no-cycle
import { api } from '../../api';

const setAuthLoading = (loading: boolean): AuthActionTypes => ({
  type: AUTH_IS_FETCHING,
  payload: loading,
});

const login = (token: string): AuthActionTypes => ({
  type: LOGIN,
  payload: { token },
});

export const logout = (): AuthActionTypes => ({
  type: LOGOUT,
});

export const postLogin = (
  loginData: LoginData,
  onSuccess?: () => void,
): AuthThunkAction => async (dispatch, getState) => {
  const { loading } = getState().auth;
  if (loading) return;
  dispatch(setAuthLoading(true));
  try {
    const response = await api.auth.fetchLogin(loginData);
    if (response.success) {
      dispatch(login(response.token));
      if (onSuccess) onSuccess();
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Login server error');
  } finally {
    dispatch(setAuthLoading(false));
  }
};
