// Core
import { useMemo } from 'react';
// Other
import { GenericData } from '../redux/types/total';

interface IUseMixedChartsDataReturn {
  labelsNames: string[];
  data1: number[];
  data2: number[];
}

export const useMixedChartsData = (
  mode: string,
  data1ForChart: GenericData[] | null,
  data2ForChart: GenericData[] | null,
): IUseMixedChartsDataReturn => useMemo(() => {
  const labelsNames: string[] = [];
  const data1: number[] = [];
  const data2: number[] = [];
  if (data1ForChart && data1ForChart.length > 0) {
    data1ForChart.forEach((s) => {
      labelsNames.push(s.label);
      data1.push(Number(s.value));
      const chartData2: GenericData | undefined = data2ForChart
        ? data2ForChart.find((d) => d.label === s.label) : undefined;
      if (chartData2) {
        data2.push(Number(chartData2.value));
      } else {
        data2.push(0);
      }
    });
  }
  return { labelsNames, data1, data2 };
}, [data1ForChart, data2ForChart, mode]);
