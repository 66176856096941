// Core
import React, {
  useMemo, useState,
} from 'react';
import { Col } from 'react-bootstrap';
import { ChartData, ChartOptions } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { shallowEqual } from 'react-redux';
// Components
import { Label } from '../Label';
// Other
import { useTypedSelector } from '../../../redux';
import { getChartColor, getChartDarkColor } from '../../../utils/helpers';
import { COLORS } from '../../../utils/constants';
// Styles
import styles from './hcharts.module.sass';
import { GenericData } from '../../../redux/types/total';

const options: ChartOptions = {
  plugins: { legend: { display: false }, tooltip: { enabled: false } },
  indexAxis: 'y',
  scales: {
    y: {
      beginAtZero: true,
      grid: { display: false },
      ticks: {
        color: COLORS.black,
        font: {
          size: 12,
        },
      },
    },
    x: {
      ticks: {
        color: COLORS.blueText,
        font: {
          size: 12,
        },
      },
    },
  },
};

const CHART_THEME1 = 'blue';
const CHART_THEME2 = 'green';
const DATASET1 = 'Touchscreen';
const DATASET2 = 'Website';

export const HorizontalCharts: React.FC = () => {
  const [darkChartColor1] = useState(getChartDarkColor(CHART_THEME1));
  const [chartColor1] = useState(getChartColor(CHART_THEME1));
  const [darkChartColor2] = useState(getChartDarkColor(CHART_THEME2));
  const [chartColor2] = useState(getChartColor(CHART_THEME2));
  const {
    locations,
    chosenLocations,
    data: { usagePerLocation, usagePerLocationWeb },
  } = useTypedSelector(({ total }) => total, shallowEqual);

  const data: ChartData | ((canvas: HTMLCanvasElement) => ChartData) = useMemo(() => {
    const labels: string[] = [];
    const dataTouch: number[] = [];
    const dataWeb: number[] = [];
    if (chosenLocations === 'All locations') {
      locations.forEach((l) => {
        labels.push(l.name);
        if (usagePerLocation && usagePerLocation.length > 0) {
          const chartDataTouch: GenericData
          | undefined = usagePerLocation.find((d) => d.label === l.id.toString());
          if (chartDataTouch) {
            dataTouch.push(Number(chartDataTouch.value));
          } else {
            dataTouch.push(0);
          }
        }
        if (usagePerLocationWeb && usagePerLocationWeb.length > 0) {
          const chartDataWeb: GenericData
          | undefined = usagePerLocationWeb.find((d) => d.label === l.id.toString());
          if (chartDataWeb) {
            dataWeb.push(Number(chartDataWeb.value));
          } else {
            dataWeb.push(0);
          }
        }
      });
    } else {
      chosenLocations.forEach((l) => {
        labels.push(l.name);
        if (usagePerLocation && usagePerLocation.length > 0) {
          const chartDataTouch: GenericData | undefined = usagePerLocation.find((d) => {
            if (l.id && d.label === l.id.toString()) return d;
            return undefined;
          });
          if (chartDataTouch) {
            dataTouch.push(Number(chartDataTouch.value));
          } else {
            dataTouch.push(0);
          }
        }
        if (usagePerLocationWeb && usagePerLocationWeb.length > 0) {
          const chartDataWeb: GenericData
          | undefined = usagePerLocationWeb.find((d) => {
            if (l.id && d.label === l.id.toString()) return d;
            return undefined;
          });
          if (chartDataWeb) {
            dataWeb.push(Number(chartDataWeb.value));
          } else {
            dataWeb.push(0);
          }
        }
      });
    }

    return {
      labels,
      datasets: [
        {
          label: DATASET1,
          data: dataTouch,
          fill: true,
          backgroundColor: chartColor1,
          hoverBackgroundColor: darkChartColor1,
          borderColor: [darkChartColor1],
          borderWidth: 1,
        },
        {
          label: DATASET2,
          data: dataWeb,
          fill: true,
          backgroundColor: chartColor2,
          hoverBackgroundColor: darkChartColor2,
          borderColor: [darkChartColor2],
          borderWidth: 1,
        },
      ],
    };
  }, [locations, chosenLocations, usagePerLocation]);

  const columnsCount = useMemo(() => {
    let rowsCount = 0;
    if (chosenLocations === 'All locations') {
      rowsCount = locations.length;
    } else rowsCount = chosenLocations.length;
    return rowsCount > 7 ? 12 : 6;
  }, [locations, chosenLocations]);

  const touchLabelJSX = usagePerLocation && usagePerLocation.length > 0 && (
  <>
    <div className={styles.hcharts__label} style={{ backgroundColor: chartColor1 }} />
    <p className={`mb-0 ${styles['hcharts__label-text']}`}>{DATASET1}</p>
  </>
  );
  const webLabelJSX = usagePerLocationWeb && usagePerLocationWeb.length > 0 && (
  <>
    <div className={styles.hcharts__label} style={{ backgroundColor: chartColor2 }} />
    <p className={`mb-0 ${styles['hcharts__label-text']}`}>{DATASET2}</p>
  </>
  );

  return (
    <Col lg={columnsCount} className="page-break-inside">
      <div className="border p-2 page-break-inside">
        <div className={`mb-4 ${styles.hcharts__header}`}>
          <div>
            <h6 className="mb-0">Touchscreen usage per location</h6>
            <p className="subtitle mb-0">Amount of sessions. One session can be converted to one user.</p>
          </div>
          <div className={styles.hcharts__labels}>
            {touchLabelJSX}
            {webLabelJSX}
          </div>
        </div>
        <div className="border px-2 py-4 relative page-break-inside">
          <Label theme="blue" text="Sessions" side="left" />
          <Bar className="page-break-inside" data={data} options={options} />
        </div>
      </div>
    </Col>
  );
};
