// Core
import React from 'react';
import { shallowEqual } from 'react-redux';
// Components
import { LineChart } from '../common/LineChart';
// Hooks:
import { useDatesChartData } from '../../hooks/useDatesChartData';
// Other
import { useTypedSelector } from '../../redux';

export const WebSessions: React.FC = () => {
  const {
    data: { totalWebSessions },
    mode,
    startDate,
    endDate,
  } = useTypedSelector(({ total }) => total, shallowEqual);
  const {
    labelsNames, chartData,
  } = useDatesChartData(mode, totalWebSessions, startDate, endDate);

  return (
    <>
      {chartData.length > 0 && (
      <LineChart
        theme="green"
        title="Website Sessions"
        subtitle="Amount started sessions. One session is one unique user."
        label="Sessions"
        labelsNames={labelsNames}
        data={chartData}
      />
      )}
    </>
  );
};
