// Core
import React from 'react';
import {
  Container, Row, Col, Navbar,
} from 'react-bootstrap';
import { useDispatch } from 'react-redux';
// Actions
import { logout } from '../../redux/actions/auth';
// Styles
import styles from './header.module.sass';

export const Header: React.FC = () => {
  const dispatch = useDispatch();
  const handleSignOutClick = () => {
    dispatch(logout());
  };

  return (
    <Row className="mb-3">
      <Col>
        <Navbar>
          <Container>
            <Navbar.Brand as="h5" className="pb-0 mb-0 pt-3">ATG Spelarguiden Analytics</Navbar.Brand>
            <Navbar.Text
              className={`justify-content-end pb-0 mb-0 pt-3 ${styles.signout}`}
              onClick={handleSignOutClick}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="grey"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
                <polyline points="16 17 21 12 16 7" />
                <line x1="21" y1="12" x2="9" y2="12" />
              </svg>
              {' '}
              <span className={styles.signout__text}>Sign Out</span>
            </Navbar.Text>
          </Container>
        </Navbar>
        <hr />
      </Col>
    </Row>
  );
};
