// Core
import { combineReducers } from 'redux';
// Reducers
/* eslint import/no-cycle: [2, { maxDepth: 1 }] */
import { authReducer as auth } from './reducers/auth';
import { totalReducer as total } from './reducers/total';

export const rootReducer = combineReducers({
  auth,
  total,
});
