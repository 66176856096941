// Core
import { useMemo } from 'react';
import moment, { Moment } from 'moment';
// Other
import { DAY_FORMAT, MONTHS } from '../utils/constants';
import { GenericData } from '../redux/types/total';
import { Mode } from '../types';
import { getDaysBetweenDates, getMonthsArr, getWeekRange } from '../utils/helpers';

interface IUseChartDataReturn {
  chartData: number[];
  labelsNames: string[];
}

type YearAverageChartDataColumn = {
  label: string;
  columnData: number;
  count: number;
  year?: number;
  week?: number;
};
type YearAverageChartData = YearAverageChartDataColumn[];

export const useDatesChartData = (
  mode: Mode,
  dataForChart: GenericData[] | null,
  startDate: Moment,
  endDate: Moment,
): IUseChartDataReturn => useMemo(() => {
  const updatedData: YearAverageChartData = [];
  const labelsNames: string[] = [];
  const chartData: number[] = [];
  if (dataForChart && dataForChart.length > 0) {
    dataForChart.forEach((d) => {
      if (mode === 'year') {
        if (d.label.length < 4) {
          const chosenMonthsArr = getMonthsArr(startDate, endDate);
          chosenMonthsArr.forEach((cm) => {
            if (cm === Number(d.label)) {
              labelsNames.push(MONTHS[Number(d.label) - 1]);
              chartData.push(Number(d.value));
            }
          });
        } else {
          const chosenDatesArr = getDaysBetweenDates(startDate, endDate);
          chosenDatesArr.forEach((data) => {
            if (moment(data).format(DAY_FORMAT) === moment(d.label).format(DAY_FORMAT)) {
              const momentDate: Moment = moment(d.label);
              const month: number = momentDate.month();
              const year: number = momentDate.year();
              const monthLabel = MONTHS[month];
              const sameMonthIdx: number = updatedData.findIndex(
                (sd) => sd.year && sd.year === year && sd.label === monthLabel,
              );
              if (sameMonthIdx === -1) {
                updatedData.push({
                  label: monthLabel,
                  year,
                  columnData: Number(d.value),
                  count: 1,
                });
              } else {
                const sameMonthData: YearAverageChartDataColumn | undefined = updatedData.find(
                  (ud) => ud.year && ud.year === year && ud.label === monthLabel,
                );
                if (sameMonthData) {
                  updatedData[sameMonthIdx] = {
                    ...sameMonthData,
                    columnData: sameMonthData.columnData + Number(d.value),
                    count: sameMonthData.count + 1,
                  };
                }
              }
            }
          });
        }
      } else if (mode === 'month') {
        if (d.label.length < 4) {
          const startWeek = startDate.week();
          const endWeek = endDate.week();
          if (Number(d.label) >= startWeek && Number(d.label) <= endWeek) {
            const weekRange = getWeekRange(Number(d.label));
            labelsNames.push(weekRange);
            chartData.push(Number(d.value));
          }
        } else {
          const chosenDatesArr = getDaysBetweenDates(startDate, endDate);
          chosenDatesArr.forEach((data) => {
            if (moment(data).format(DAY_FORMAT) === moment(d.label).format(DAY_FORMAT)) {
              const momentDate: Moment = moment(d.label);
              const week: number = momentDate.week();
              const year: number = momentDate.year();
              const sameWeekIdx: number = updatedData.findIndex(
                (ud) => ud.year && ud.year === year && ud.week && ud.week === week,
              );
              if (sameWeekIdx === -1) {
                updatedData.push({
                  label: getWeekRange(week),
                  year,
                  columnData: Number(d.value),
                  count: 1,
                });
              } else {
                const sameWeekData: YearAverageChartDataColumn | undefined = updatedData.find(
                  (sd) => sd.year && sd.year === year && sd.week && sd.week === week,
                );
                if (sameWeekData) {
                  updatedData[sameWeekIdx] = {
                    ...sameWeekData,
                    columnData: sameWeekData.columnData + Number(d.value),
                    count: sameWeekData.count + 1,
                  };
                }
              }
            }
          });
        }
      } else if (d.label.length < 4) {
        // TODO: after backend changes output data only for chosen dates range
        labelsNames.push(d.label);
        chartData.push(Number(d.value));
      } else {
        const chosenDatesArr = getDaysBetweenDates(startDate, endDate);
        chosenDatesArr.forEach((data) => {
          if (moment(data).format(DAY_FORMAT) === moment(d.label).format(DAY_FORMAT)) {
            labelsNames.push(d.label);
            chartData.push(Number(d.value));
          }
        });
      }
    });
    if (updatedData.length > 0) {
      updatedData.forEach((data) => {
        labelsNames.push(data.label);
        chartData.push(data.columnData / data.count);
      });
    }
  }
  return { labelsNames, chartData };
}, [dataForChart, mode, startDate, endDate]);
