// Core
import React from 'react';
import moment from 'moment';
import { Stack } from 'react-bootstrap';
// Other
import { Operation } from '../../types';
import { DAY_FORMAT, TODAY } from '../../utils/constants';
// Styles
import styles from './vmodal.module.sass';

interface ITopPartProps {
  startDay: string;
  onArrowClick: (operation: Operation) => void;
  title: string;
  week: string[];
}

const YEAR_AGO: string = moment().subtract(12, 'month').format(DAY_FORMAT);

const TopPartMemoized: React.FC<ITopPartProps> = (props: ITopPartProps) => {
  const {
    startDay, onArrowClick, title, week,
  } = props;

  const leftArrowJSX = startDay !== YEAR_AGO && (
  <svg
    onClick={() => onArrowClick('subtract')}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 451.847 451.847"
    enableBackground="new 0 0 451.847 451.847"
    xmlSpace="preserve"
  >
    <g>
      <path fill="#6C757D" d="M97.141,225.92c0-8.095,3.091-16.192,9.259-22.366L300.689,9.27c12.359-12.359,32.397-12.359,44.751,0c12.354,12.354,12.354,32.388,0,44.748L173.525,225.92l171.903,171.909c12.354,12.354,12.354,32.391,0,44.744c-12.354,12.365-32.386,12.365-44.745,0l-194.29-194.281C100.226,242.115,97.141,234.018,97.141,225.92z" />
    </g>
  </svg>
  );
  const rightArrowJSX = startDay !== TODAY && (
  <svg
    onClick={() => onArrowClick('add')}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 451.846 451.847"
    enableBackground="new 0 0 451.846 451.847"
    xmlSpace="preserve"
  >
    <g>
      <path fill="#6C757D" d="M345.441,248.292L151.154,442.573c-12.359,12.365-32.397,12.365-44.75,0c-12.354-12.354-12.354-32.391,0-44.744L278.318,225.92L106.409,54.017c-12.354-12.359-12.354-32.394,0-44.748c12.354-12.359,32.391-12.359,44.75,0l194.287,194.284c6.177,6.18,9.262,14.271,9.262,22.366C354.708,234.018,351.617,242.115,345.441,248.292z" />
    </g>
  </svg>
  );

  return (
    <>
      <div className={styles.body__header}>
        <div className={styles.body__arrow}>
          {leftArrowJSX}
        </div>
        <p>{title}</p>
        <div className={styles.body__arrow}>
          {rightArrowJSX}
        </div>
      </div>
      <div className={`${styles.body__core} py-3`}>
        <div className={styles.body__xy}>Location</div>
        <Stack className={styles.body__y} direction="horizontal" gap={1}>
          {week.map((day) => (
            <div className={styles.body__col} key={day}>{day.split(' ')[0]}</div>
          ))}
        </Stack>
      </div>
    </>
  );
};

export const TopPart = React.memo(TopPartMemoized);
