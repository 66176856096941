// Core
import React from 'react';
// Other
import { ThemeType } from '../../../types';
// Styles
import styles from './label.module.sass';

interface ILabelProps {
  theme: ThemeType;
  text: string;
  side: 'left' | 'right';
}

const LabelMemoized: React.FC<ILabelProps> = ({ theme, text, side }: ILabelProps) => (
  <p className={`${styles[`chart-label__${theme}`]} ${styles[`chart-label__${side}`]} ${styles['chart-label']}`}>{text}</p>
);

export const Label = React.memo(LabelMemoized);
