// Core
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Container } from 'react-bootstrap';
// Pages
import { Statistics } from '../pages/Statistics';

export const PersonalRoutes: React.FC = () => (
  <Container>
    <Switch>
      <Route exact path="/analytics" component={Statistics} />
      <Redirect exact from="/(login)?" to="/analytics" />
    </Switch>
  </Container>
);
