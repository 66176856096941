// Core
import React, { useEffect } from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
// Components
import { TabsBar } from '../../components/TabsBar';
import { SearchBar } from '../../components/SearchBar';
import { Header } from '../../components/Header';
// Actions
import { postAnalytics, getLocations } from '../../redux/actions/total';
// Other
import { useTypedSelector } from '../../redux';
import { LocationsOption } from '../../types';
import { DAY_FORMAT } from '../../utils/constants';

export const Statistics: React.FC = () => {
  const {
    mode, chosenLocations, startDate, endDate,
  } = useTypedSelector(({ total }) => total, shallowEqual);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getLocations());
    dispatch(postAnalytics(
      startDate.format(DAY_FORMAT),
      endDate.format(DAY_FORMAT),
      mode,
      Array.isArray(chosenLocations) ? chosenLocations : [] as LocationsOption[],
    ));
  }, []);

  return (
    <>
      <Header />
      <SearchBar />
      <TabsBar />
    </>
  );
};
