// Core
import moment, { Moment } from 'moment';
// Other
import { ThemeType } from '../types';
import { DAY_FORMAT } from './constants';

export const getAuthHeaders = (isPost?: boolean): HeadersInit => {
  const token = localStorage && localStorage.getItem('atgsToken');
  const headers: HeadersInit = { Accept: 'application/json' };
  if (localStorage && token) headers.Authorization = `Bearer ${token}`;
  if (isPost) headers['Content-Type'] = 'application/json';
  return headers;
};

export const getChartColor = (theme: ThemeType): string => {
  switch (theme) {
    case 'blue':
      return 'rgba(64, 226, 226, 0.9)';
      break;
    case 'green':
      return 'rgba(69, 225, 64, 0.9)';
      break;
    case 'darkblue':
      return 'rgba(65, 145, 225, 0.9)';
      break;
    default:
      return 'rgba(64, 226, 226, 0.9)';
  }
};

export const getChartDarkColor = (theme: ThemeType): string => {
  switch (theme) {
    case 'blue':
      return 'rgba(31, 205, 206, 1)';
      break;
    case 'green':
      return 'rgba(37, 206, 35, 1)';
      break;
    case 'darkblue':
      return 'rgba(1, 100, 199, 1)';
      break;
    default:
      return 'rgba(64, 226, 226, 1)';
  }
};

export const hasStringHTMLTags = (value?: string): boolean => {
  if (!value) {
    return true;
  }
  if (value?.search(/<[a-z/][\s\S]*/) !== -1) {
    return false;
  }
  return true;
};

export const getDaysBetweenDates = (startDate: Moment, endDate: Moment): string[] => {
  const clonedStartDate = startDate.clone();
  const dates = [];

  while (clonedStartDate.isSameOrBefore(endDate)) {
    dates.push(clonedStartDate.format(DAY_FORMAT));
    clonedStartDate.add(1, 'days');
  }
  return dates;
};

export const getWeekRange = (week: number): string => {
  const startWeekDay: Moment = moment().week(week).day(1);
  const endWeekDay: Moment = moment().week(week).day(7);
  const startWeekDayString = `${startWeekDay.format('D')}.${startWeekDay.format('M')}`;
  const endWeekDayString = `${endWeekDay.format('D')}.${endWeekDay.format('M')}`;
  return `${startWeekDayString} - ${endWeekDayString}`;
};

export const getMonthsArr = (startDate: Moment, endDate: Moment): number[] => {
  const clonedStartDate = startDate.clone();
  const monthsValues: number[] = [];

  while (endDate > clonedStartDate || clonedStartDate.format('M') === endDate.format('M')) {
    monthsValues.push(clonedStartDate.month() + 1);
    clonedStartDate.add(1, 'month');
  }
  return monthsValues;
};
