// Core
import { batch } from 'react-redux';
import moment from 'moment';
// Types
import {
  AnalyticsData,
  ANALYTICS_FILL,
  ANALYTICS_TOUCH_FILL,
  ANALYTICS_VISITORS_FILL,
  TOTAL_IS_FETCHING, VISITORS_FILL,
  TotalActionTypes,
  TotalThunkAction,
  ErrorAnalyticsApi,
  VisitorsData,
  DATE_RANGE_UPDATE, LOCATIONS_CHOOSE,
  LOCATIONS_FILL,
  LocationsData,
  MODE_UPDATE, AnalyticsTouchData, AnalyticsVisitorsData, PrefilledVisitors, VISITORS_IS_FETCHING,
} from '../types/total';
import {
  ChosenLocations, DateRange, LocationsOption, Mode,
} from '../../types';
// Other
import { api } from '../../api';
import { WEEK_AGO, YESTERDAY } from '../../utils/constants';

// Sync
export function setIsFetching(payload: boolean): TotalActionTypes {
  return {
    type: TOTAL_IS_FETCHING,
    payload,
  };
}

export function fillAnalytics(payload: AnalyticsData): TotalActionTypes {
  return {
    type: ANALYTICS_FILL,
    payload,
  };
}

export function fillTouchAnalytics(payload: AnalyticsTouchData): TotalActionTypes {
  return {
    type: ANALYTICS_TOUCH_FILL,
    payload,
  };
}

export function fillVisitorsAnalytics(payload: AnalyticsVisitorsData): TotalActionTypes {
  return {
    type: ANALYTICS_VISITORS_FILL,
    payload,
  };
}

export function setIsVisitorsFetching(payload: boolean): TotalActionTypes {
  return {
    type: VISITORS_IS_FETCHING,
    payload,
  };
}

export function fillVisitors(payload: PrefilledVisitors): TotalActionTypes {
  return {
    type: VISITORS_FILL,
    payload,
  };
}

export function fillLocations(payload: LocationsData): TotalActionTypes {
  return {
    type: LOCATIONS_FILL,
    payload,
  };
}

export function updateDateRange(payload: DateRange): TotalActionTypes {
  return {
    type: DATE_RANGE_UPDATE,
    payload,
  };
}

export function updateMode(payload: Mode): TotalActionTypes {
  return {
    type: MODE_UPDATE,
    payload,
  };
}

export function chooseLocations(payload: ChosenLocations): TotalActionTypes {
  return {
    type: LOCATIONS_CHOOSE,
    payload,
  };
}

/**
 * Get locations:
 */
export const getLocations = (): TotalThunkAction => async (dispatch) => {
  dispatch(setIsFetching(true));
  try {
    const response = await api.fetchLocations(dispatch);
    dispatch(fillLocations(response));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Get locations server error');
  } finally {
    dispatch(setIsFetching(false));
  }
};

/**
 * Post search options and get corresponding charts data:
 */
export const postAnalytics = (
  startDate: string,
  endDate: string,
  mode: Mode,
  locations: LocationsOption[],
  onSuccess?: () => void,
  onError?: (error?: ErrorAnalyticsApi) => void,
): TotalThunkAction => async (dispatch) => {
  dispatch(setIsFetching(true));
  try {
    const response = await Promise.allSettled([
      api.analytics.fetchWebAnalytics({
        startDate, endDate, mode, locations,
      }, dispatch),
      api.analytics.fetchTouchAnalytics({
        startDate, endDate, mode, locations,
      }, dispatch),
      api.analytics.fetchManualVisitors({
        startDate, endDate, mode, locations,
      }, dispatch),
    ]);
    if (response[0].status === 'rejected' && response[1].status === 'rejected' && response[2].status === 'rejected') {
      dispatch(updateMode('week'));
      dispatch(updateDateRange({
        startDate: WEEK_AGO,
        endDate: YESTERDAY,
      }));
      dispatch(chooseLocations('All locations'));
      if (onError) onError();
      // eslint-disable-next-line no-console
      console.error('Post analytics error');
    } else {
      batch(() => {
        dispatch(updateDateRange({
          startDate: moment(startDate),
          endDate: moment(endDate),
        }));
        dispatch(updateMode(mode));
        dispatch(chooseLocations(locations.length > 0 ? locations : 'All locations'));
      });
      if (response[0].status === 'fulfilled') dispatch(fillAnalytics(response[0].value));
      if (response[1].status === 'fulfilled') dispatch(fillTouchAnalytics(response[1].value));
      if (response[2].status === 'fulfilled') dispatch(fillVisitorsAnalytics(response[2].value));
      if (onSuccess) onSuccess();
      // eslint-disable-next-line no-console
      if (response[0].status === 'rejected') console.error('Post website analytics error');
      // eslint-disable-next-line no-console
      if (response[1].status === 'rejected') console.error('Post touchscreen analytics error');
      // eslint-disable-next-line no-console
      if (response[2].status === 'rejected') console.error('Post visitors analytics error');
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Post analytics server error');
    dispatch(updateDateRange({
      startDate: WEEK_AGO,
      endDate: YESTERDAY,
    }));
    dispatch(chooseLocations('All locations'));
    dispatch(updateMode('week'));
    if (onError) onError();
  } finally {
    dispatch(setIsFetching(false));
  }
};

/**
 * Get prefilled add visitors modal data:
 */
export const getVisitors = (
  year: number,
  week: number,
  onSuccess?: () => void,
  onError?: (error?: ErrorAnalyticsApi) => void,
): TotalThunkAction => async (dispatch) => {
  dispatch(setIsVisitorsFetching(true));
  try {
    const response = await api.visitors.fetchPrefilledVisitors(year, week, dispatch);
    dispatch(fillVisitors(response));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Get prefilled visitors server error');
    if (onError) onError();
  } finally {
    dispatch(setIsVisitorsFetching(false));
  }
};

/**
 * Post add visitors modal:
 */
export const postVisitors = (
  visitorsData: VisitorsData,
  onSuccess?: () => void,
  onError?: (error?: ErrorAnalyticsApi) => void,
): TotalThunkAction => async (dispatch) => {
  dispatch(setIsVisitorsFetching(true));
  try {
    await api.visitors.fetchVisitors(visitorsData, dispatch);
    if (onSuccess) onSuccess();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Post visitors server error', error);
    if (onError) onError();
  } finally {
    dispatch(setIsVisitorsFetching(false));
  }
};
