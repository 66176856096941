// Core
import React from 'react';
import { Row, Col, Spinner } from 'react-bootstrap';
import { shallowEqual } from 'react-redux';
// Components
import { ModulesPerSessionTouch } from './ModulesPerSessionTouch';
import { GuidesPerSessionTouch } from './GuidesPerSessionTouch';
import { ViewsPerSessionTouch } from './ViewsPerSessionTouch';
import { ModulesPerSession } from './ModulesPerSession';
import { GuidesPerSession } from './GuidesPerSession';
import { ViewsPerSession } from './ViewsPerSession';
// Other
import { useTypedSelector } from '../../redux';

export const SessionsTab: React.FC = () => {
  const {
    modulesPerSessionTouch,
    modulesPerSession,
    guidesPerSessionTouch,
    guidesPerSession,
    viewsPerSessionTouch,
    viewsPerSession,
  } = useTypedSelector(({ total }) => total.data, shallowEqual);

  const sessionsModulesJSX = ((modulesPerSessionTouch && modulesPerSessionTouch.length > 0)
      || (modulesPerSession && modulesPerSession.length > 0)) && (
      <>
        <h3 className="mb-3">Session data / Modules</h3>
        <Row className="mb-5">
          <ModulesPerSessionTouch />
          <ModulesPerSession />
        </Row>
      </>
  );
  const sessionsGuidesJSX = ((guidesPerSessionTouch && guidesPerSessionTouch.length > 0)
      || (guidesPerSession && guidesPerSession.length > 0)) && (
      <>
        <h3 className="mb-3">Session data / Guides (Only Module 1 & 2)</h3>
        <Row className="mb-5">
          <GuidesPerSessionTouch />
          <GuidesPerSession />
        </Row>
      </>
  );
  const sessionsViewsJSX = ((viewsPerSessionTouch && viewsPerSessionTouch.length > 0)
      || (viewsPerSession && viewsPerSession.length > 0)) && (
      <>
        <h3 className="mb-3">Session data / Views</h3>
        <Row className="mb-3">
          <ViewsPerSessionTouch />
          <ViewsPerSession />
        </Row>
      </>
  );

  if ((!modulesPerSessionTouch || (modulesPerSessionTouch && modulesPerSessionTouch.length === 0))
        && (!modulesPerSession || (modulesPerSession && modulesPerSession.length === 0))) {
    return (
      <div className="mt-5 loader-wrapper">
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  return (
    <Row>
      <Col>
        <div className="border px-3 py-2 mb-5">
          {sessionsModulesJSX}
          {sessionsGuidesJSX}
          {sessionsViewsJSX}
        </div>
      </Col>
    </Row>
  );
};
