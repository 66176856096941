// Core
import React from 'react';
import {
  Nav, Row, Col, Tab, Stack, Container, Button,
} from 'react-bootstrap';
import 'react-dates/initialize';
// Components
import { TotalTab } from '../TotalTab';
import { ModuleTab } from '../ModuleTab';
import { SessionsTab } from '../SessionsTab';
import { VisitorsModal } from '../VisitorsModal';
// Styles
import styles from './tabsbar.module.sass';

export const TabsBar: React.FC = () => {
  const [modalShow, setModalShow] = React.useState(false);

  const handleAddButtonClick = () => {
    setModalShow(true);
  };

  return (
    <>
      <Row>
        <Col>
          <Container>
            <Tab.Container
              id="controlled-tabs"
              defaultActiveKey="total"
            >
              <Stack direction="horizontal" gap={3} className={styles['tabs-bar']}>
                <Nav variant="pills" className={styles['tabs-bar__nav']}>
                  <Nav.Item className={styles['tabs-bar__item']}>
                    <Nav.Link eventKey="total" className={styles['tabs-bar__link']}>Total usage</Nav.Link>
                  </Nav.Item>
                  <Nav.Item className={styles['tabs-bar__item']}>
                    <Nav.Link eventKey="module" className={styles['tabs-bar__link']}>Module usage</Nav.Link>
                  </Nav.Item>
                  <Nav.Item className={styles['tabs-bar__item']}>
                    <Nav.Link eventKey="sessions" className={styles['tabs-bar__link']}>Sessions data</Nav.Link>
                  </Nav.Item>
                </Nav>
                <div className="ms-auto">
                  <Stack direction="horizontal" gap={3} id={styles['buttons-wrapper']}>
                    <Button variant="primary" className={`button ${styles['add-btn']}`} onClick={handleAddButtonClick}>+ Add track visitors</Button>
                    <Button variant="primary" className="button" id={styles.print__btn} onClick={() => window.print()}>
                      <svg className={styles.print__icon} enableBackground="new 0 0 512 512" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                        <g>
                          <path stroke="white" fill="white" d="m451 128.787-128.787-128.787h-261.213v512h390zm-120-77.574 68.787 68.787h-68.787zm90 430.787h-330v-452h210v120h120z" />
                        </g>
                      </svg>
                      <span className={styles['btn-text']}>
                        Generate report
                      </span>
                    </Button>
                  </Stack>
                </div>
              </Stack>
              <Tab.Content>
                <Tab.Pane eventKey="total">
                  <TotalTab />
                </Tab.Pane>
                <Tab.Pane eventKey="module">
                  <ModuleTab />
                </Tab.Pane>
                <Tab.Pane eventKey="sessions">
                  <SessionsTab />
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Container>
        </Col>
      </Row>
      <VisitorsModal
        onHide={() => setModalShow(false)}
        show={modalShow}
      />
    </>
  );
};
