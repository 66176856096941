// Core
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  Modal, Button, Form, Stack,
} from 'react-bootstrap';
import { Formik, FormikValues } from 'formik';
import * as yup from 'yup';
import { AnyObject, AssertsShape, TypeOfShape } from 'yup/es/object';
import moment from 'moment';
import { Moment } from 'moment/moment';
import { shallowEqual, useDispatch } from 'react-redux';
// Components
import { TopPart } from './TopPart';
// Actions
import { getVisitors, postVisitors } from '../../redux/actions/total';
// Other
import { DAY_FORMAT, FULLSCREEN_MODAL_BREAKPOINT, TODAY } from '../../utils/constants';
import { Operation } from '../../types';
import { useTypedSelector } from '../../redux';
import {
  DayVisitors, Location, PrefilledVisitorsRow, VisitorsData,
} from '../../redux/types/total';
// Styles
import styles from './vmodal.module.sass';

interface IVisitorsModalProps {
  onHide: () => void;
  show: boolean;
}

const VisitorsModalMemoized: React.FC<IVisitorsModalProps> = (props: IVisitorsModalProps) => {
  const { onHide, show } = props;
  const [startDay, setStartDay] = useState<string>(TODAY);
  const {
    prefilledVisitors,
    isVisitorsFetching,
  } = useTypedSelector(({ total }) => total, shallowEqual);
  const [newVisitors, setNewVisitors] = useState<PrefilledVisitorsRow[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (show) {
      setNewVisitors([...prefilledVisitors]);
    } else {
      setNewVisitors([]);
    }
  }, [prefilledVisitors, show]);

  const handleArrowClick = (operation: Operation) => {
    setStartDay((prevStart) => {
      const prevStartMoment = moment(prevStart);
      return operation === 'subtract'
        ? prevStartMoment.subtract(7, 'days').format(DAY_FORMAT)
        : prevStartMoment.add(7, 'days').format(DAY_FORMAT);
    });
  };

  useEffect(() => {
    if (show) {
      dispatch(getVisitors(
        moment(startDay).day(1).year(),
        moment(startDay).day(1).isoWeek(),
      ));
    }
  }, [startDay, show]);

  const datesTitle: string = useMemo(() => {
    const startWeekDay: Moment = moment(startDay).day(1);
    return `${startWeekDay.format('MMMM')} ${startWeekDay.format('YYYY')}`;
  }, [startDay]);

  const weekDates: string[] = useMemo(() => {
    const weekDatesNew: string[] = [];
    const startDayMoment: Moment = moment(startDay);
    for (let i = 1; i <= 7; i++) {
      const momentDay = startDayMoment.day(i);
      weekDatesNew.push(`${momentDay.date()}/${Number(momentDay.month()) + 1} ${momentDay.year()}`);
    }
    return weekDatesNew;
  }, [startDay]);

  const [initialVModalValues, vModalSchema, locations]: [
    FormikValues,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    yup.ObjectSchema<any, AnyObject, TypeOfShape<any>, AssertsShape<any>>,
    Location[],
  ] = useMemo(() => {
    const initialValuesObj: FormikValues = {};
    const initialSchemaShape: AnyObject = {};
    const prefilledLocations: Location[] = [];
    if ((newVisitors.length > 0) && (weekDates.length > 0)) {
      newVisitors.forEach((v) => {
        prefilledLocations.push(v.location);
        const locationId = v.location.id;
        weekDates.forEach((d) => {
          const sameDateData: DayVisitors | undefined = v.Visitors.find((sd) => {
            const sdYear = moment(sd.date).year();
            const sdMonth = moment(sd.date).month() + 1;
            const sdDay = moment(sd.date).date();
            const dateParts = d.split(' ');
            const wdDayMonth = dateParts[0].split('/');
            if (sdYear === Number(dateParts[1]) && sdMonth === Number(wdDayMonth[1])
                && sdDay === Number(wdDayMonth[0])) {
              return sd;
            }
            return undefined;
          });
          initialValuesObj[`${locationId},${d}`] = sameDateData && sameDateData.visitors !== 0 ? sameDateData.visitors.toString() : '';
          initialSchemaShape[`${locationId},${d}`] = yup.string().matches(/^[0-9]*$/, { excludeEmptyString: true });
        });
      });
    }
    return [
      initialValuesObj,
      yup.object().shape(initialSchemaShape),
      prefilledLocations,
    ];
  }, [newVisitors, weekDates]);

  const defineValue = useCallback(
    (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
      val: any, locId: number, date: string,
    ): string => {
      if (typeof val === 'string') return val;
      const valRow: PrefilledVisitorsRow
      | undefined = newVisitors.find((pv) => pv.location.id === locId);
      if (newVisitors.length > 0 && valRow) {
        const sameDateVis: DayVisitors | undefined = valRow.Visitors.find((sd) => {
          const sdYear = moment(sd.date).year();
          const sdMonth = moment(sd.date).month() + 1;
          const sdDay = moment(sd.date).date();
          const dateParts = date.split(' ');
          const wdDayMonth = dateParts[0].split('/');
          if (sdYear === Number(dateParts[1]) && sdMonth === Number(wdDayMonth[1])
            && sdDay === Number(wdDayMonth[0])) {
            return sd;
          }
          return undefined;
        });
        return sameDateVis && sameDateVis.visitors !== 0 ? sameDateVis.visitors.toString() : '';
      }
      return '';
    },
    [newVisitors, show],
  );

  const handleModalSubmit = (values: FormikValues) => {
    const notEmptyValues: VisitorsData = {};
    Object.entries(values).forEach((entry) => {
      const [key, value] = entry;
      if (value !== '') notEmptyValues[key] = value.toString();
    });
    dispatch(postVisitors(notEmptyValues, () => {
      onHide();
    }));
  };

  const defineIsDisabledControl = (date: string): boolean => {
    const dateParts = date.split(' ');
    const year = Number(dateParts[1]);
    const dayMonth = dateParts[0].split('/');
    const todayMonth = Number(moment().month()) + 1;
    const todayYear = moment().year();
    const month = Number(dayMonth[1]);
    if (
      (month > todayMonth && year === todayYear)
        || ((year === todayYear) && (month === todayMonth)
            && (Number(dayMonth[0]) > moment().date()))
    ) return true;
    return false;
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="visitors-modal-title"
      centered
      fullscreen={FULLSCREEN_MODAL_BREAKPOINT}
      show={show}
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title id="visitors-modal-title">
          + Add track visitors
        </Modal.Title>
      </Modal.Header>
      <Formik
        validationSchema={vModalSchema}
        onSubmit={handleModalSubmit}
        initialValues={initialVModalValues}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          isValid,
          errors,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Modal.Body>
              {(weekDates.length > 0) && startDay && (
                <TopPart
                  startDay={startDay}
                  onArrowClick={handleArrowClick}
                  title={datesTitle}
                  week={weekDates}
                />
              )}
              {(weekDates.length > 0) && (locations.length > 0) && locations.map((location) => (
                <div className={`${styles.body__core} mb-1`} key={`location ${location.id}`}>
                  <div className={styles.body__x}>{location.name}</div>
                  <Stack className={styles.body__table} direction="horizontal" gap={1}>
                    {weekDates.map((day) => (
                      <Form.Control
                        style={{ paddingRight: '.75rem' }}
                        key={`${location.id}${day}`}
                        type="text"
                        name={`${location.id},${day}`}
                        value={defineValue(values[`${location.id},${day}`], location.id, day)}
                        onChange={handleChange}
                        isInvalid={!!errors[`${location.id},${day}`]}
                        disabled={defineIsDisabledControl(day)}
                      />
                    ))}
                  </Stack>
                </div>
              ))}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={onHide}>Close</Button>
              <Button disabled={!isValid || isVisitorsFetching} variant="primary" type="submit" onClick={onHide}>Save Changes</Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export const VisitorsModal = React.memo(VisitorsModalMemoized);
