// Core
import React from 'react';
import { shallowEqual } from 'react-redux';
// Components
import { BarChart } from '../common/BarChart';
// Hooks:
import { useDatesChartData } from '../../hooks/useDatesChartData';
// Other
import { useTypedSelector } from '../../redux';

export const TouchSessionTime: React.FC = () => {
  const {
    mode,
    data: { totalTouchSessionTime },
    startDate,
    endDate,
  } = useTypedSelector(({ total }) => total, shallowEqual);
  const {
    labelsNames, chartData,
  } = useDatesChartData(mode, totalTouchSessionTime, startDate, endDate);

  return (
    <>
      {chartData.length > 0 && (
        <BarChart
          theme="blue"
          title="Touchscreen Session time"
          subtitle="Time spent on one session. One session can be converted to one user."
          labelsNames={labelsNames}
          data={chartData}
        />
      )}
    </>
  );
};
