// Core
import React, { useMemo } from 'react';
import { Col } from 'react-bootstrap';
import { Chart, ChartData, ChartOptions } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
// Other
import { COLORS } from '../../../utils/constants';
import { GenericData } from '../../../redux/types/total';
// Styles
import styles from './doughnutchart.module.sass';

interface IDoughnutChartProps {
  title: string;
  subtitle: string;
  dataForChart: GenericData[] | null;
  children?: never;
}

Chart.register(ChartDataLabels);
Chart.defaults.set('plugins.datalabels', {
  color: COLORS.transparent,
});

const options: ChartOptions = {
  plugins: {
    legend: { display: false },
    tooltip: {
      enabled: false,
    },
    datalabels: {
      color: COLORS.black,
      anchor: 'start',
      font: {
        size: 14,
      },
      formatter(value, context) {
        const idx: number = context.dataIndex;
        const labelText: unknown = context.chart.data.labels?.[idx] || '';
        return `${value}% ${labelText}`;
      },
    },
  },
};

const BACKGROUNDS: string[] = ['#823C8C', '#C82878', '#007BFF', 'rgba(69, 225, 64, 1)', 'rgba(64, 226, 226, 1)', 'rgba(65, 145, 225, 1)'];

const DoughnutChartMemoized: React.FC<IDoughnutChartProps> = (props:IDoughnutChartProps) => {
  const {
    title, subtitle, dataForChart,
  } = props;
  const data: ChartData | ((canvas: HTMLCanvasElement) => ChartData) = useMemo(() => {
    const labels: string[] = [];
    const chartData: number[] = [];
    let totalData = 0;
    if (dataForChart && dataForChart.length > 0) {
      dataForChart.forEach((s) => {
        labels.push(s.label);
        totalData += Number(s.value);
        chartData.push(Number(s.value));
      });
    }
    return {
      labels,
      datasets: [
        {
          label: title,
          data: chartData.map((d) => (totalData > 0 ? Math.round((d / totalData) * 100) : 0)),
          fill: true,
          backgroundColor: labels.length > 0 ? BACKGROUNDS.slice(0, labels.length) : '#823C8C',
        },
      ],
    };
  }, [dataForChart]);

  return (
    <Col lg={6} className="mb-3 mb-lg-0 page-break-inside">
      <div className="border p-2 page-break-inside">
        <h6 className="mb-0">{title}</h6>
        <p className="subtitle">{subtitle}</p>
        <div className={`page-break-inside mb-4 pt-1 ${styles['doughnut-chart__wrapper']}`}>
          <div className={`${styles['doughnut-chart']} page-break-inside`}>
            <Doughnut className="page-break-inside" data={data} options={options} />
          </div>
        </div>
      </div>
    </Col>
  );
};

DoughnutChartMemoized.defaultProps = {
  children: undefined,
};

export const DoughnutChart = React.memo(DoughnutChartMemoized);
