// Core
import React, { useMemo } from 'react';
import { Col } from 'react-bootstrap';
import { ChartData, ChartOptions } from 'chart.js';
import { Line } from 'react-chartjs-2';
// Components
import { Label } from '../Label';
// Other
import { getChartColor, getChartDarkColor } from '../../../utils/helpers';
import { IChartProps } from '../../../types';
import { COLORS } from '../../../utils/constants';

interface IBarChartsProps extends IChartProps {
  xLabels: string[];
  data1: number[];
  data2: number[];
  children?: never;
}

const options: ChartOptions = {
  plugins: {
    legend: { display: false },
    tooltip: { enabled: false },
  },
  scales: {
    timeSec: {
      position: 'left',
      beginAtZero: true,
      ticks: {
        color: COLORS.greyText,
        font: {
          size: 12,
        },
      },
      grid: {
        drawBorder: false,
      },
    },
    sessions: {
      position: 'right',
      ticks: {
        color: COLORS.greyText,
        font: {
          size: 12,
        },
      },
      grid: {
        drawBorder: false,
      },
    },
    x: {
      grid: { display: false },
      ticks: {
        color: COLORS.black,
        font: {
          size: 12,
        },
      },
    },
  },
};

const MixedChartsMemoized: React.FC<IBarChartsProps> = (props:IBarChartsProps) => {
  const {
    title, subtitle, theme, xLabels, data1, data2,
  } = props;
  const chartColor: string = useMemo(() => getChartColor(theme), [theme]);
  const darkChartColor: string = useMemo(() => getChartDarkColor(theme), [theme]);
  const data: ChartData | ((canvas: HTMLCanvasElement) => ChartData) = {
    labels: xLabels,
    datasets: [
      {
        type: 'line',
        label: 'Sessions',
        data: data2,
        fill: false,
        borderWidth: 1,
        borderColor: darkChartColor,
        yAxisID: 'sessions',
        order: 1,
        pointRadius: 0,
        pointBorderWidth: 0,
      },
      {
        type: 'bar',
        label: 'Time [sec]',
        data: data1,
        backgroundColor: chartColor,
        hoverBackgroundColor: chartColor,
        borderWidth: 0,
        yAxisID: 'timeSec',
        order: 2,
      },
    ],
  };

  return (
    <Col lg={6} className="mb-3 mb-lg-0 page-break-inside">
      <div className="border p-2 page-break-inside">
        <h6 className="mb-0">{title}</h6>
        <p className="mb-4 subtitle">{subtitle}</p>
        <div className="border px-2 py-4 relative page-break-inside">
          <Label theme={theme} text="Sessions" side="left" />
          <Label theme={theme} text="Time [sec]" side="right" />
          <Line className="page-break-inside" data={data} options={options} />
        </div>
      </div>
    </Col>
  );
};

MixedChartsMemoized.defaultProps = {
  children: undefined,
};

export const MixedCharts = React.memo(MixedChartsMemoized);
