// Core
import { Moment } from 'moment';
// Types
/* eslint import/no-cycle: [2, { maxDepth: 1 }] */
import {
  Total,
  ANALYTICS_FILL, ANALYTICS_TOUCH_FILL, VISITORS_IS_FETCHING,
  TOTAL_IS_FETCHING,
  TotalActionTypes,
  LOCATIONS_FILL,
  MODE_UPDATE,
  LOCATIONS_CHOOSE,
  DATE_RANGE_UPDATE,
  Location, ANALYTICS_VISITORS_FILL, VISITORS_FILL, PrefilledVisitors,
} from '../types/total';
import { Mode, ChosenLocations } from '../../types';
// Other
import { WEEK_AGO, YESTERDAY } from '../../utils/constants';

export type TotalState = {
  data: Total;
  isFetching: boolean;
  isVisitorsFetching: boolean;
  startDate: Moment;
  endDate: Moment;
  mode: Mode;
  chosenLocations: ChosenLocations;
  locations: Location[];
  prefilledVisitors: PrefilledVisitors;
};

const initialState: TotalState = {
  data: {
    totalTouchSessions: null,
    totalWebSessions: null,
    totalTouchSessionTime: null,
    totalWebSessionTime: null,
    visitorsAnalytics: null,
    usagePerLocation: null,
    usagePerLocationWeb: null,
    usageTouchPerModule: null,
    usagePerModule: null,
    module1guide1Touch: null,
    module1guide1: null,
    module1guide1timeTouch: null,
    module1guide1time: null,
    module1guide2Touch: null,
    module1guide2: null,
    module1guide2timeTouch: null,
    module1guide2time: null,
    module1guide3Touch: null,
    module1guide3: null,
    module1guide3timeTouch: null,
    module1guide3time: null,
    module2guide1Touch: null,
    module2guide1: null,
    module2guide1timeTouch: null,
    module2guide1time: null,
    module2guide2Touch: null,
    module2guide2: null,
    module2guide2timeTouch: null,
    module2guide2time: null,
    module2guide3Touch: null,
    module2guide3: null,
    module2guide3timeTouch: null,
    module2guide3time: null,
    module3perarticle: null,
    module3perarticleTouch: null,
    module3percategory: null,
    module3percategoryTouch: null,
    timePerModuleTouch: null,
    timePerModule: null,
    timePerGuideTouch: null,
    timePerGuide: null,
    touchNavigation: null,
    websiteNavigation: null,
    modulesPerSessionTouch: null,
    modulesPerSession: null,
    guidesPerSessionTouch: null,
    guidesPerSession: null,
    viewsPerSessionTouch: null,
    viewsPerSession: null,
  },
  startDate: WEEK_AGO,
  endDate: YESTERDAY,
  mode: 'week',
  chosenLocations: 'All locations',
  locations: [],
  isFetching: false,
  isVisitorsFetching: false,
  prefilledVisitors: [],
};

export const totalReducer = (
  state = initialState,
  action: TotalActionTypes,
): TotalState => {
  switch (action.type) {
    case TOTAL_IS_FETCHING:
      return {
        ...state,
        isFetching: action.payload,
      };
    case VISITORS_IS_FETCHING:
      return {
        ...state,
        isVisitorsFetching: action.payload,
      };
    case ANALYTICS_FILL:
      return {
        ...state,
        data: {
          ...state.data,
          totalWebSessions: Array.isArray(action.payload.total_web_sessions)
            ? [...action.payload.total_web_sessions] : action.payload.total_web_sessions,
          totalWebSessionTime: Array.isArray(action.payload.sessions_time)
            ? [...action.payload.sessions_time] : action.payload.sessions_time,
          usagePerLocationWeb: Array.isArray(action.payload.usage_per_location)
            ? [...action.payload.usage_per_location]
            : action.payload.usage_per_location,
          usagePerModule: Array.isArray(action.payload.usage_per_module)
            ? [...action.payload.usage_per_module] : action.payload.usage_per_module,
          module1guide1: Array.isArray(action.payload.module_1_guide_1)
            ? [...action.payload.module_1_guide_1] : action.payload.module_1_guide_1,
          module1guide1time: Array.isArray(action.payload.module_1_guide_1_time)
            ? [...action.payload.module_1_guide_1_time] : action.payload.module_1_guide_1_time,
          module1guide2: Array.isArray(action.payload.module_1_guide_2)
            ? [...action.payload.module_1_guide_2] : action.payload.module_1_guide_2,
          module1guide2time: Array.isArray(action.payload.module_1_guide_2_time)
            ? [...action.payload.module_1_guide_2_time] : action.payload.module_1_guide_2_time,
          module1guide3: Array.isArray(action.payload.module_1_guide_3)
            ? [...action.payload.module_1_guide_3] : action.payload.module_1_guide_3,
          module1guide3time: Array.isArray(action.payload.module_1_guide_3_time)
            ? [...action.payload.module_1_guide_3_time] : action.payload.module_1_guide_3_time,
          module2guide1: Array.isArray(action.payload.module_2_guide_1)
            ? [...action.payload.module_2_guide_1] : action.payload.module_2_guide_1,
          module2guide1time: Array.isArray(action.payload.module_2_guide_1_time)
            ? [...action.payload.module_2_guide_1_time] : action.payload.module_2_guide_1_time,
          module2guide2: Array.isArray(action.payload.module_2_guide_2)
            ? [...action.payload.module_2_guide_2] : action.payload.module_2_guide_2,
          module2guide2time: Array.isArray(action.payload.module_2_guide_2_time)
            ? [...action.payload.module_2_guide_2_time] : action.payload.module_2_guide_2_time,
          module2guide3: Array.isArray(action.payload.module_2_guide_3)
            ? [...action.payload.module_2_guide_3] : action.payload.module_2_guide_3,
          module2guide3time: Array.isArray(action.payload.module_2_guide_3_time)
            ? [...action.payload.module_2_guide_3_time] : action.payload.module_2_guide_3_time,
          module3perarticle: Array.isArray(action.payload.module_3_per_article)
            ? [...action.payload.module_3_per_article] : action.payload.module_3_per_article,
          module3percategory: Array.isArray(action.payload.module_3_per_category)
            ? [...action.payload.module_3_per_category] : action.payload.module_3_per_category,
          timePerModule: Array.isArray(action.payload.time_per_module)
            ? [...action.payload.time_per_module] : action.payload.time_per_module,
          timePerGuide: Array.isArray(action.payload.time_per_guide)
            ? [...action.payload.time_per_guide] : action.payload.time_per_guide,
          websiteNavigation: Array.isArray(action.payload.website_navigation)
            ? [...action.payload.website_navigation] : action.payload.website_navigation,
          modulesPerSession: Array.isArray(action.payload.modules_per_session)
            ? [...action.payload.modules_per_session] : action.payload.modules_per_session,
          guidesPerSession: Array.isArray(action.payload.guides_per_session)
            ? [...action.payload.guides_per_session] : action.payload.guides_per_session,
          viewsPerSession: Array.isArray(action.payload.views_per_session)
            ? [...action.payload.views_per_session] : action.payload.views_per_session,
        },
      };
    case ANALYTICS_TOUCH_FILL:
      return {
        ...state,
        data: {
          ...state.data,
          totalTouchSessions: Array.isArray(action.payload.total_web_sessions)
            ? [...action.payload.total_web_sessions] : action.payload.total_web_sessions,
          totalTouchSessionTime: Array.isArray(action.payload.sessions_time)
            ? [...action.payload.sessions_time] : action.payload.sessions_time,
          usagePerLocation: Array.isArray(action.payload.usage_per_location)
            ? [...action.payload.usage_per_location] : action.payload.usage_per_location,
          usageTouchPerModule: Array.isArray(action.payload.usage_per_module)
            ? [...action.payload.usage_per_module] : action.payload.usage_per_module,
          module1guide1Touch: Array.isArray(action.payload.module_1_guide_1)
            ? [...action.payload.module_1_guide_1] : action.payload.module_1_guide_1,
          module1guide1timeTouch: Array.isArray(action.payload.module_1_guide_1_time)
            ? [...action.payload.module_1_guide_1_time] : action.payload.module_1_guide_1_time,
          module1guide2Touch: Array.isArray(action.payload.module_1_guide_2)
            ? [...action.payload.module_1_guide_2] : action.payload.module_1_guide_2,
          module1guide2timeTouch: Array.isArray(action.payload.module_1_guide_2_time)
            ? [...action.payload.module_1_guide_2_time] : action.payload.module_1_guide_2_time,
          module1guide3Touch: Array.isArray(action.payload.module_1_guide_3)
            ? [...action.payload.module_1_guide_3] : action.payload.module_1_guide_3,
          module1guide3timeTouch: Array.isArray(action.payload.module_1_guide_3_time)
            ? [...action.payload.module_1_guide_3_time] : action.payload.module_1_guide_3_time,
          module2guide1Touch: Array.isArray(action.payload.module_2_guide_1)
            ? [...action.payload.module_2_guide_1] : action.payload.module_2_guide_1,
          module2guide1timeTouch: Array.isArray(action.payload.module_2_guide_1_time)
            ? [...action.payload.module_2_guide_1_time] : action.payload.module_2_guide_1_time,
          module2guide2Touch: Array.isArray(action.payload.module_2_guide_2)
            ? [...action.payload.module_2_guide_2] : action.payload.module_2_guide_2,
          module2guide2timeTouch: Array.isArray(action.payload.module_2_guide_2_time)
            ? [...action.payload.module_2_guide_2_time] : action.payload.module_2_guide_2_time,
          module2guide3Touch: Array.isArray(action.payload.module_2_guide_3)
            ? [...action.payload.module_2_guide_3] : action.payload.module_2_guide_3,
          module2guide3timeTouch: Array.isArray(action.payload.module_2_guide_3_time)
            ? [...action.payload.module_2_guide_3_time] : action.payload.module_2_guide_3_time,
          module3perarticleTouch: Array.isArray(action.payload.module_3_per_article)
            ? [...action.payload.module_3_per_article] : action.payload.module_3_per_article,
          module3percategoryTouch: Array.isArray(action.payload.module_3_per_category)
            ? [...action.payload.module_3_per_category] : action.payload.module_3_per_category,
          timePerModuleTouch: Array.isArray(action.payload.time_per_module)
            ? [...action.payload.time_per_module] : action.payload.time_per_module,
          timePerGuideTouch: Array.isArray(action.payload.time_per_guide)
            ? [...action.payload.time_per_guide] : action.payload.time_per_guide,
          touchNavigation: Array.isArray(action.payload.website_navigation)
            ? [...action.payload.website_navigation] : action.payload.website_navigation,
          modulesPerSessionTouch: Array.isArray(action.payload.modules_per_session)
            ? [...action.payload.modules_per_session] : action.payload.modules_per_session,
          guidesPerSessionTouch: Array.isArray(action.payload.guides_per_session)
            ? [...action.payload.guides_per_session] : action.payload.guides_per_session,
          viewsPerSessionTouch: Array.isArray(action.payload.views_per_session)
            ? [...action.payload.views_per_session] : action.payload.views_per_session,
        },
      };
    case ANALYTICS_VISITORS_FILL:
      return {
        ...state,
        data: {
          ...state.data,
          visitorsAnalytics: Array.isArray(action.payload)
            ? [...action.payload] : action.payload,
        },
      };
    case LOCATIONS_FILL:
      return {
        ...state,
        locations: [...action.payload.filter((l) => l.name !== 'Test ATG')],
        chosenLocations: 'All locations',
      };
    case VISITORS_FILL:
      return {
        ...state,
        prefilledVisitors: [...action.payload.filter((l) => l.location.name !== 'Test ATG')],
      };
    case DATE_RANGE_UPDATE:
      return {
        ...state,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
      };
    case MODE_UPDATE:
      return {
        ...state,
        mode: action.payload,
      };
    case LOCATIONS_CHOOSE:
      return {
        ...state,
        chosenLocations: action.payload,
      };
    default:
      // eslint-disable-next-line no-case-declarations, @typescript-eslint/no-unused-vars
      const x: never = action;
  }

  return state;
};
