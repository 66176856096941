// Core
import React from 'react';
import { shallowEqual } from 'react-redux';
// Components
import { MixedCharts } from '../common/MixedCharts';
// Hooks:
import { useMixedChartsData } from '../../hooks/useMixedChartsData';
// Other
import { useTypedSelector } from '../../redux';

export const Module1Guide1: React.FC = () => {
  const {
    data: { module1guide1time, module1guide1 },
    mode,
  } = useTypedSelector(({ total }) => total, shallowEqual);
  const { labelsNames, data1, data2 } = useMixedChartsData(mode, module1guide1, module1guide1time);

  return (
    <>
      {labelsNames.length > 0 && (
      <MixedCharts
        theme="green"
        title="Website Module 1 Drop-out / Harry Boy Guide"
        subtitle="Amount of sessions per view. And session time per view."
        xLabels={labelsNames}
        data1={data1}
        data2={data2}
      />
      )}
    </>
  );
};
