// Core
import { ThunkAction } from 'redux-thunk';
// Other
/* eslint import/no-cycle: [2, { maxDepth: 1 }] */
import { AppState } from '../index';

export interface LoginData {
  username: string;
  password: string;
}

export type LoginApiData = {
  success: boolean;
  message: string;
  token: string;
};

export const AUTH_IS_FETCHING = 'AUTH_IS_FETCHING';
type AuthIsFetchingAction = {
  type: typeof AUTH_IS_FETCHING;
  payload: boolean;
};

export const LOGIN = 'LOGIN';
export type LoginAction = {
  type: typeof LOGIN;
  payload: {
    token: string;
  };
};

export const LOGOUT = 'LOGOUT';
type LogoutAction = {
  type: typeof LOGOUT;
};

export type AuthActionTypes =
    | AuthIsFetchingAction
    | LogoutAction
    | LoginAction;

export type AuthThunkAction = ThunkAction<Promise<void>, AppState, unknown, AuthActionTypes>;
