// Core
import React, { useMemo } from 'react';
import { Col } from 'react-bootstrap';
import { ChartData, ChartOptions } from 'chart.js';
import { Line } from 'react-chartjs-2';
// Components
import { Label } from '../Label';
// Other
import { getChartColor, getChartDarkColor } from '../../../utils/helpers';
import { IChartProps } from '../../../types';
import { COLORS } from '../../../utils/constants';

interface ILineChartProps extends IChartProps {
  label: string;
  labelsNames?: string[];
  data?: number[];
  children?: never;
}

const options: ChartOptions = {
  plugins: { legend: { display: false }, tooltip: { enabled: false } },
  scales: {
    y: {
      beginAtZero: true,
      ticks: {
        color: COLORS.greyText,
        font: {
          size: 12,
        },
      },
      grid: {
        drawBorder: false,
      },
    },
    x: {
      grid: { display: false },
      ticks: {
        maxRotation: 0,
        minRotation: 0,
        padding: 0,
        color: COLORS.greyText,
        font: {
          size: 12,
        },
      },
    },
  },
};

const LineChartMemoized: React.FC<ILineChartProps> = (props:ILineChartProps) => {
  const {
    title, subtitle, theme, label, data, labelsNames,
  } = props;

  const backgroundColor: string = useMemo(() => getChartColor(theme), [theme]);
  const chartDarkColor: string = useMemo(() => getChartDarkColor(theme), [theme]);
  const lineChartData: ChartData | ((canvas: HTMLCanvasElement) => ChartData) = useMemo(() => ({
    labels: (labelsNames && labelsNames.length > 0) ? labelsNames : ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG'],
    datasets: [
      {
        data: (data && data.length > 0)
          ? data : [540, 800, 1600, 1200, 850, 1300, 1750, 1800],
        fill: true,
        backgroundColor,
        pointBackgroundColor: chartDarkColor,
        borderColor: chartDarkColor,
        pointBorderColor: '#ffffff',
        borderWidth: 1,
        pointBorderWidth: 1,
        tension: 0.3,
        pointRadius: 3,
      },
    ],
  }), [data, labelsNames]);

  return (
    <Col lg={6} className="mb-3 mb-lg-0 page-break-inside">
      <div className="border p-2 page-break-inside">
        <h6 className="mb-0">{title}</h6>
        <p className="mb-4 subtitle">{subtitle}</p>
        <div className="border px-2 py-4 relative page-break-inside">
          <Label theme={theme} text={label} side="left" />
          <Line className="page-break-inside" data={lineChartData} options={options} />
        </div>
      </div>
    </Col>
  );
};

LineChartMemoized.defaultProps = {
  data: [],
  labelsNames: [],
  children: undefined,
};

export const LineChart = React.memo(LineChartMemoized);
