// Core
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
// Pages
import { Login } from '../pages/Login';

export const AuthRoutes: React.FC = () => (
  <Switch>
    <Route exact path="/login" component={Login} />
    <Redirect to="/login" />
  </Switch>
);
