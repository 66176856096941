// Core
import React, { useState } from 'react';
import {
  Row, Col, Container, Button, Form,
} from 'react-bootstrap';
import { shallowEqual, useDispatch } from 'react-redux';
import { Formik, FormikValues } from 'formik';
import moment, { Moment } from 'moment';
import { DateRangePicker, FocusedInputShape, isInclusivelyBeforeDay } from 'react-dates';
// Components
import { Label } from '../common/Label';
import { Locations } from './Locations';
// Actions
import { postAnalytics } from '../../redux/actions/total';
// Other
import { DAY_FORMAT, WEEK_AGO, YESTERDAY } from '../../utils/constants';
import { LocationsOption, Mode } from '../../types';
// Styles
import styles from './searchbar.module.sass';
import { useTypedSelector } from '../../redux';

// Start react-dates date pickers' weeks from Monday:
moment.updateLocale('en', {
  week: {
    dow: 1,
  },
});
moment.weekdays(true);

export const SearchBar: React.FC = () => {
  const {
    startDate: storedStartDate,
    endDate: storedEndDate,
    mode: storedMode,
  } = useTypedSelector(({ total }) => total, shallowEqual);
  const [rangeStartDate, setStartDate] = React.useState<Moment | null>(storedStartDate);
  const [rangeEndDate, setEndDate] = React.useState<Moment | null>(storedEndDate);
  const [rangeFocusedInput, setRangeFocusedInput] = useState<FocusedInputShape | null>(null);
  const [activeMode, setActiveMode] = useState<Mode>(storedMode);
  const [multiSelections, setMultiSelections] = useState<LocationsOption[]>([{ name: 'All locations' }]);
  const dispatch = useDispatch();

  const onDatesChange = ({ startDate, endDate }: {
    startDate: Moment | null;
    endDate: Moment | null;
  }) => {
    if (startDate) setStartDate(startDate);
    if (endDate) setEndDate(endDate);
  };

  const handleModeClick = (mode: Mode) => {
    setActiveMode(mode);
  };

  const handleSearchSubmit = (
    values: FormikValues,
    actions: { setSubmitting: (arg0: boolean) => void; },
  ) => {
    dispatch(postAnalytics(
      rangeStartDate !== null ? rangeStartDate.format(DAY_FORMAT) : WEEK_AGO.format(DAY_FORMAT),
      rangeEndDate !== null ? rangeEndDate.format(DAY_FORMAT) : YESTERDAY.format(DAY_FORMAT),
      activeMode,
      multiSelections[0].name === 'All locations' ? [] as LocationsOption[] : multiSelections,
      () => {
        actions.setSubmitting(false);
      },
      () => {
        actions.setSubmitting(false);
        setStartDate(WEEK_AGO);
        setEndDate(YESTERDAY);
        setActiveMode('week');
        setMultiSelections([{ name: 'All locations' }]);
      },
    ));
  };

  return (
    <Row>
      <Col>
        <Container>
          <Formik
            onSubmit={handleSearchSubmit}
            initialValues={{
              startDate: storedStartDate.format(DAY_FORMAT),
              endDate: storedEndDate.format(DAY_FORMAT),
              locations: 'All locations',
              mode: storedMode,
            }}
          >
            {({
              handleSubmit,
              isSubmitting,
            }) => (
              <Form noValidate onSubmit={handleSubmit} className={`${styles.bar}`}>
                <div className={`border p-4 mb-3 mb-xxl-5 ${styles.bar__dates}`}>
                  <Label theme="blue" text="Date range" side="left" />
                  <DateRangePicker
                    required
                    startDate={rangeStartDate}
                    startDateId="startDate"
                    endDate={rangeEndDate}
                    endDateId="endDate"
                    onDatesChange={onDatesChange}
                    focusedInput={rangeFocusedInput}
                    onFocusChange={(focusedInput) => setRangeFocusedInput(focusedInput)}
                    hideKeyboardShortcutsPanel
                    customArrowIcon={<span />}
                    noBorder
                    initialVisibleMonth={() => moment().subtract(1, 'month')}
                    isOutsideRange={(day) => !day.isAfter(moment().subtract(36, 'month')) || !isInclusivelyBeforeDay(day, moment())}
                  />
                </div>
                <div className={`border p-4 mb-3 mb-xxl-5 ${styles.bar__modes}`}>
                  <Label theme="blue" text="Mode" side="left" />
                  <div className={styles['bar__modes-container']}>
                    <button
                      onClick={() => handleModeClick('week')}
                      type="button"
                      className={`border mb-0 ${activeMode === 'week' ? styles['bar__mode-active'] : styles.bar__mode}`}
                    >
                      Days
                    </button>
                    <button
                      onClick={() => handleModeClick('month')}
                      type="button"
                      className={`border mb-0 ${activeMode === 'month' ? styles['bar__mode-active'] : styles.bar__mode}`}
                    >
                      Weeks
                    </button>
                    <button
                      onClick={() => handleModeClick('year')}
                      type="button"
                      className={`border mb-0 ${activeMode === 'year' ? styles['bar__mode-active'] : styles.bar__mode}`}
                    >
                      Months
                    </button>
                  </div>
                </div>
                <Locations selections={multiSelections} setSelections={setMultiSelections} />
                <Button
                  variant="primary"
                  type="submit"
                  disabled={isSubmitting}
                  className="px-sm-4 mb-5 button"
                >
                  Apply
                </Button>
              </Form>
            )}
          </Formik>
        </Container>
      </Col>
    </Row>
  );
};
