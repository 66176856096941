// Core
import React, { useMemo } from 'react';
import { Col } from 'react-bootstrap';
import { ChartData, ChartOptions } from 'chart.js';
import { Bar } from 'react-chartjs-2';
// Components
import { Label } from '../Label';
// Other
import { getChartColor, getChartDarkColor } from '../../../utils/helpers';
import { IChartProps } from '../../../types';
import { COLORS } from '../../../utils/constants';

interface IBarChartProps extends IChartProps {
  labelsNames?: string[];
  data?: number[];
  children?: never;
}

const options: ChartOptions = {
  plugins: { legend: { display: false }, tooltip: { enabled: false } },
  scales: {
    y: {
      beginAtZero: true,
      ticks: {
        color: COLORS.greyText,
        font: {
          size: 12,
        },
      },
      grid: {
        drawBorder: false,
      },
    },
    x: {
      grid: { display: false },
      ticks: {
        color: COLORS.greyText,
        font: {
          size: 12,
        },
        maxRotation: 0,
        minRotation: 0,
        padding: 0,
      },
    },
  },
};

const BarChartMemoized: React.FC<IBarChartProps> = (props:IBarChartProps) => {
  const {
    title, subtitle, theme, data, labelsNames,
  } = props;
  const backgroundColor: string = useMemo(() => getChartColor(theme), [theme]);
  const darkChartColor: string = useMemo(() => getChartDarkColor(theme), [theme]);
  const barChartData: ChartData | ((canvas: HTMLCanvasElement) => ChartData) = useMemo(() => ({
    labels: (labelsNames && labelsNames.length > 0) ? labelsNames : ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG'],
    datasets: [
      {
        data: (data && data.length > 0)
          ? data : [540, 800, 1600, 1200, 850, 1300, 1750, 1800],
        fill: true,
        backgroundColor,
        hoverBackgroundColor: darkChartColor,
        borderWidth: 0,
      },
    ],
  }), [data, labelsNames]);

  return (
    <Col lg={6} className="mb-3 mb-lg-0 page-break-inside">
      <div className="border p-2 page-break-inside">
        <h6 className="mb-0">{title}</h6>
        <p className="mb-4 subtitle">{subtitle}</p>
        <div className="border px-2 py-4 relative page-break-inside">
          <Label theme={theme} text="Time [sec]" side="left" />
          <Bar className="page-break-inside" data={barChartData} options={options} />
        </div>
      </div>
    </Col>
  );
};

BarChartMemoized.defaultProps = {
  data: [],
  labelsNames: [],
  children: undefined,
};

export const BarChart = React.memo(BarChartMemoized);
