// Core
import React from 'react';
import { Row, Col, Spinner } from 'react-bootstrap';
import { shallowEqual } from 'react-redux';
// Components
import { HorizontalChart } from '../common/HorizontalBarChart';
import { DoughnutChart } from '../common/DoughnutChart';
import { Module1Guide1 } from './Module1Guide1';
import { Module1Guide2 } from './Module1Guide2';
import { Module1Guide3 } from './Module1Guide3';
import { Module2Guide1 } from './Module2Guide1';
import { Module2Guide2 } from './Module2Guide2';
import { Module2Guide3 } from './Module2Guide3';
import { Module1Guide1Touch } from './Module1Guide1Touch';
import { Module1Guide2Touch } from './Module1Guide2Touch';
import { Module1Guide3Touch } from './Module1Guide3Touch';
import { Module2Guide1Touch } from './Module2Guide1Touch';
import { Module2Guide2Touch } from './Module2Guide2Touch';
import { Module2Guide3Touch } from './Module2Guide3Touch';
// Other
import { useTypedSelector } from '../../redux';

export const ModuleTab: React.FC = () => {
  const {
    timePerModule,
    timePerGuide,
    websiteNavigation,
    timePerModuleTouch,
    timePerGuideTouch,
    touchNavigation,
    module1guide1Touch,
    module1guide1,
    module1guide2Touch,
    module1guide2,
    module1guide3Touch,
    module1guide3,
    module2guide1Touch,
    module2guide1,
    module2guide2Touch,
    module2guide2,
    module2guide3Touch,
    module2guide3,
    module3perarticle,
    module3perarticleTouch,
    module3percategory,
    module3percategoryTouch,
  } = useTypedSelector(({ total }) => total.data, shallowEqual);

  const module1JSX = ((module1guide1Touch && module1guide1Touch.length > 0)
      || (module1guide1 && module1guide1.length > 0)
      || (module1guide2Touch && module1guide2Touch.length > 0)
      || (module1guide2 && module1guide2.length > 0)
      || (module1guide3Touch && module1guide3Touch.length > 0)
      || (module1guide3 && module1guide3.length > 0)) && (
      <>
        <h3 className="mb-3">Module usage / Module 1 / Drop-out & Session time</h3>
        <Row className="mb-lg-3">
          <Module1Guide1Touch />
          <Module1Guide1 />
        </Row>
        <Row className="mb-lg-3">
          <Module1Guide2Touch />
          <Module1Guide2 />
        </Row>
        <Row className="mb-5">
          <Module1Guide3Touch />
          <Module1Guide3 />
        </Row>
      </>
  );
  const module2JSX = ((module2guide1Touch && module2guide1Touch.length > 0)
      || (module2guide1 && module2guide1.length > 0)
      || (module2guide2Touch && module2guide2Touch.length > 0)
      || (module2guide2 && module2guide2.length > 0)
      || (module2guide3Touch && module2guide3Touch.length > 0)
      || (module2guide3 && module2guide3.length > 0)) && (
      <>
        <h3 className="mb-3">Module usage / Module 2 / Drop-out & Session time</h3>
        <Row className="mb-lg-3">
          <Module2Guide1Touch />
          <Module2Guide1 />
        </Row>
        <Row className="mb-lg-3">
          <Module2Guide2Touch />
          <Module2Guide2 />
        </Row>
        <Row className="mb-5">
          <Module2Guide3Touch />
          <Module2Guide3 />
        </Row>
      </>
  );
  const timePerModuleJSX = ((timePerModuleTouch && timePerModuleTouch.length > 0)
      || (timePerModule && timePerModule.length > 0)
      || (timePerGuideTouch && timePerGuideTouch.length > 0)
      || (timePerGuide && timePerGuide.length > 0)) && (
      <>
        <h3 className="mb-3">Module usage / Session time</h3>
        <Row className="mb-lg-3">
          <HorizontalChart
            theme="blue"
            title="Touchscreen Session time per module"
            subtitle="Time spent per module."
            label="Time [sec]"
            dataForChart={timePerModuleTouch}
          />
          <HorizontalChart
            theme="green"
            title="Website Session time per module"
            subtitle="Time spent per module."
            label="Time [sec]"
            dataForChart={timePerModule}
          />
        </Row>
        <Row className="mb-5">
          <HorizontalChart
            theme="blue"
            title="Touchscreen Session time per guide"
            subtitle="Time spent per guide."
            label="Time [sec]"
            dataForChart={timePerGuideTouch}
          />
          <HorizontalChart
            theme="green"
            title="Website Session time per guide"
            subtitle="Time spent per guide."
            label="Time [sec]"
            dataForChart={timePerGuide}
          />
        </Row>
        <h3 className="mb-3">Module usage / Module 3 - Categories & Articles</h3>
        <Row className="mb-5">
          <HorizontalChart
            theme="blue"
            title="Touchscreen Usage per category"
            subtitle="Views per category."
            label="Time [sec]"
            dataForChart={module3percategoryTouch}
          />
          <HorizontalChart
            theme="green"
            title="Website Usage per category"
            subtitle="Views per category."
            label="Time [sec]"
            dataForChart={module3percategory}
          />
        </Row>
        <Row className="mb-5">
          <HorizontalChart
            theme="blue"
            title="Touchscreen Usage per article"
            subtitle="Views per article."
            label="Time [sec]"
            dataForChart={module3perarticleTouch}
          />
          <HorizontalChart
            theme="green"
            title="Website Usage per article"
            subtitle="Views per article."
            label="Time [sec]"
            dataForChart={module3perarticle}
          />
        </Row>
      </>
  );
  const navigationJSX = ((touchNavigation && touchNavigation.length > 0)
      || (websiteNavigation && websiteNavigation.length > 0)) && (
      <>
        <h3 className="mb-3">Module usage / Module 1 & 2 Start navigation</h3>
        <Row className="mb-3">
          <DoughnutChart
            title="Touchscreen Navigation"
            dataForChart={touchNavigation}
            subtitle="% of taps on first step of Question-navigation contra the guide buttons on M1 & 2 start view"
          />
          <DoughnutChart
            title="Website Navigation"
            dataForChart={websiteNavigation}
            subtitle="% of clicks on first step of Question-navigation contra the guide buttons on M1 & 2 start view"
          />
        </Row>
      </>
  );

  if ((!module1guide1Touch || (module1guide1Touch && module1guide1Touch.length === 0))
        && (!module1guide1 || (module1guide1 && module1guide1.length === 0))
        && (!module3perarticle || !module3percategory)
        && (!module1guide2Touch || (module1guide2Touch && module1guide2Touch.length === 0))
        && (!module1guide2 || (module1guide2 && module1guide2.length === 0))) {
    return (
      <div className="mt-5 loader-wrapper">
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  return (
    <Row>
      <Col>
        <div className="border px-3 py-2 mb-5">
          {module1JSX}
          {module2JSX}
          {timePerModuleJSX}
          {navigationJSX}
        </div>
      </Col>
    </Row>
  );
};
